import React from 'react'
import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import { Badge, Button, Card, Col, Dropdown, DropdownButton, OverlayTrigger, Popover, Row, Tooltip } from 'react-bootstrap'
import { formatDate, formatTime } from '../../util/date'
import { getBroadcastStatusBackground, getCameraStatusLabel, getEventStatusLabel, getIncomingStreamStatusLabel, getLivestreamTitle, hasPrivilege } from '../../util'
import LivestreamNotesModal from './LivestreamNotesModal'
import LivestreamPriceModal from './LivestreamPriceModal'
import LivestreamVideoFileUploadModal from '../../containers/elements/LivestreamVideoFileUploadModal'
import LivestreamOutputInfoModal from './LivestreamOutputInfoModal'
import ConfirmationModal from './ConfirmationModal'
import LivestreamInfoModal from './LivestreamInfoModal'
import LivestreamPlayerModal from './LivestreamPlayerModal'
import LivestreamLocationContactDetailsModal from './LivestreamLocationContactDetailsModal'
import LabeledSwitch from './LabeledSwitch'
import { PRIV_CANCEL_LIVESTREAM, PRIV_IGNORE_ACTIVITY, PRIV_RESET_LIVESTREAM, PRIV_SET_GAME_IGNORE_OVERLAYS, PRIV_SET_GAME_PRICE, PRIV_SET_GAME_STATUS, PRIV_STOP_LIVESTREAM, PRIV_UPDATE_LIVESTREAM_TITLE, PRIV_UPLOAD_STREAM_VIDEO_FILE, PRIV_VIEW_GAME_NOTES, PRIV_VIEW_STREAM_INFO, PRIV_VIEW_STREAM_OUTPUT_INFO, PRIV_VIEW_STREAM_PLAYER } from '../../util/constants'
import LivestreamVideoListModal from './LivestreamVideoListModal'

class Livestream extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            confirmationModalHeading:        null,
            confirmationModalOpen:           false,
            confirmationModalText:           null,
            confirmationModalTitle:          null,
            hasExecutingStreamActionError:   false,
            infoModalOpen:                   false,
            isExecutingStreamAction:         false,
            isSettingIgnoreOverlays:         false,
            isSettingLivestreamStatus:       false,
            locationContactDetailsModalOpen: false,
            notesModalOpen:                  false,
            outputInfoModalOpen:             false,
            playerModalOpen:                 false,
            priceModalOpen:                  false,
            streamAction:                    null,
            streamActionInfinitive:          null,
            videoFileUploadModalOpen:        false,
            videoListModalOpen:              false,

        }

    }

    addVideo( video ) {

        video && this.changeItem( { videos: [ ...this.props.item.videos, {

            Id:           video.Id,
            Thumbnail:    null,
            Title:        video.title,
            Originalname: video.originalname,
            Description:  null,
            Path:         null,
            Filesystem:   null,
            Duration:     video.duration,
            CreatedAt:    null,
            UpdatedAt:    null,

        } ] } )

    }

    changeItem( e ) {

        this.props.onChange( { ...this.props.item, ...e } )

    }

    confirmStreamAction() {

        this.setState( { confirmationModalOpen: false, hasExecutingStreamActionError: false, isExecutingStreamAction: true }, () => setTimeout( () => {

            const err = () => this.setState( { hasExecutingStreamActionError: true }, () => setTimeout( () => this.setState( { hasExecutingStreamActionError: false } ), 3000 ) )

            ajax( getDataUrl( 'api/Broadcast/' + this.state.streamAction ), { token: this.props.token, streamId: this.props.item.Id, provId: this.props.item.ProviderIdentifier }, { method: 'POST' } )
                .then( e => ! e.StatusId && err() )
                .catch( () => err() )
                .finally( () => this.setState( { isExecutingStreamAction: false } ) )

        }, 1000 ) )

    }

    executeStreamAction( action ) {

        const conf = {

            setIgnoreInactivity: { text: _( 'Bist du sicher, dass du bei diesem Stream "Inaktivität ignorieren" setzen möchtest?'           ), heading: _( 'Inaktivität ignorieren'                     ), infinitive: _( '"Inaktivität ignorieren" wird gesetzt...' ) },
            resetStream:         { text: _( 'Bist du sicher, dass du diesen Stream erneut starten möchtest?'                                ), heading: _( 'Stream erneut starten'                      ), infinitive: _( 'Stream wird erneut gestartet...'          ) },
            CancelStream:        { text: _( 'Bist du sicher, dass du diesen Stream canceln möchtest? Das Recording wird NICHT gespeichert.' ), heading: _( 'Stream canceln (Recording NICHT speichern)' ), infinitive: _( 'Stream wird gecancelt...'                 ) },
            StopStream:          { text: _( 'Bist du sicher, dass du diesen Stream stoppen möchtest? Das Recording wird gespeichert.'       ), heading: _( 'Stream stoppen (Recording speichern)'       ), infinitive: _( 'Stream wird gestoppt...'                  ) },

        }

        this.setState( {

            confirmationModalHeading: conf[ action ].heading,
            confirmationModalOpen:    true,
            confirmationModalText:    conf[ action ].text,
            confirmationModalTitle:   getLivestreamTitle( this.props.item ),
            streamAction:             action,
            streamActionInfinitive:   conf[ action ].infinitive,

        } )

    }

    hideConfirmationModal() {

        this.setState( { confirmationModalOpen: false } )

    }

    setIgnoreOverlays( e ) {

        const checked = e.target.checked

        this.setState( { isSettingIgnoreOverlays: true }, () =>

            ajax( getDataUrl( 'api/support/SetGameIgnoreOverlays' ), { provId: this.props.item.ProviderIdentifier, overlay: checked, token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && this.changeItem( { ignoreOverlays: checked } ) )
                .finally( () => this.setState( { isSettingIgnoreOverlays: false } ) )

        )

    }

    setLivestreamStatus( e ) {

        const checked = e.target.checked

        this.setState( { isSettingLivestreamStatus: true }, () =>

            ajax( getDataUrl( 'api/Broadcast/SetGamestatus' ), { provId: this.props.item.ProviderIdentifier, state: Number( checked ), token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && this.changeItem( { Broadcastable: checked } ) )
                .finally( () => this.setState( { isSettingLivestreamStatus: false } ) )

        )

    }

    toggleInfoModal() {

        this.setState( { infoModalOpen: ! this.state.infoModalOpen } )

    }

    toggleLocationContactDetailsModal() {

        this.setState( { locationContactDetailsModalOpen: ! this.state.locationContactDetailsModalOpen } )

    }

    toggleNotesModal() {

        this.setState( { notesModalOpen: ! this.state.notesModalOpen } )

    }

    togglePlayerModal() {

        this.setState( { playerModalOpen: ! this.state.playerModalOpen } )

    }

    togglePriceModal() {

        this.setState( { priceModalOpen: ! this.state.priceModalOpen } )

    }

    toggleOutputInfoModal() {

        this.setState( { outputInfoModalOpen: ! this.state.outputInfoModalOpen } )

    }

    toggleVideoFileUploadModal() {

        this.setState( { videoFileUploadModalOpen: ! this.state.videoFileUploadModalOpen } )

    }

    toggleVideoListModal() {

        this.setState( { videoListModalOpen: ! this.state.videoListModalOpen } )

    }

    updateStreamName() {

        this.setState( {

            streamAction:           'UpdateStreamName',
            streamActionInfinitive: _( 'Streamtitel wird aktualisiert...' ),

        }, () => this.confirmStreamAction() )

    }

    renderNotesButton( hasNotes ) {

        return (

            <Button variant='link' onClick={ () => this.toggleNotesModal() } className={ hasNotes ? 'text-info' : 'text-dark' }><Icon icon='circle-info' size='2' /></Button>

        )

    }

    render() {

        const canSetGamePrice          = hasPrivilege( this.props.user, PRIV_SET_GAME_PRICE           )
        const canStopLivestream        = hasPrivilege( this.props.user, PRIV_STOP_LIVESTREAM          )
        const canViewGameNotes         = hasPrivilege( this.props.user, PRIV_VIEW_GAME_NOTES          )
        const canIgnoreActivity        = hasPrivilege( this.props.user, PRIV_IGNORE_ACTIVITY          )
        const canResetLivestream       = hasPrivilege( this.props.user, PRIV_RESET_LIVESTREAM         )
        const canCancelLivestream      = hasPrivilege( this.props.user, PRIV_CANCEL_LIVESTREAM        )
        const canUpdateLivestreamTitle = hasPrivilege( this.props.user, PRIV_UPDATE_LIVESTREAM_TITLE  )
        const canViewStreamOutputInfo  = hasPrivilege( this.props.user, PRIV_VIEW_STREAM_OUTPUT_INFO  )
        const canSetGameIgnoreOverlays = hasPrivilege( this.props.user, PRIV_SET_GAME_IGNORE_OVERLAYS )
        const canUploadStreamVideoFile = ( hasPrivilege( this.props.user, PRIV_UPLOAD_STREAM_VIDEO_FILE ) && this.props.item.UploadAllowed )
        const ignoreOverlaysSwitch     = <LabeledSwitch checked={ this.props.item.ignoreOverlays } onChange={ e => this.setIgnoreOverlays( e ) } disabled={ ! this.props.item.showIgnoreOverlay || this.state.isSettingIgnoreOverlays || ! canSetGameIgnoreOverlays } label={ _( 'Ignore Overlays' ) } />

        return (

            <>

                <Card className='mb-2 livestream-item'>

                    <Card.Body>

                        <Row className='align-items-center'>

                            <Col xs='4' sm='3' md='2' xl='1' className='text-center'>

                                <OverlayTrigger overlay={<Popover><Popover.Body>{ this.props.item.BroadcastStatus || 'Unknown' }</Popover.Body></Popover>}>

                                    <Badge bg={ getBroadcastStatusBackground( this.props.item.BroadcastStatus ) }>

                                        {

                                            this.props.item.gameIsTBD ?

                                                <div>TBD</div>

                                            :

                                                <>

                                                    <div>{ formatDate( this.props.item.StartTime, { weekday: 'short', year: 'none' } ) }</div>

                                                    <div>{ formatTime( this.props.item.StartTime ) }</div>

                                                </>

                                        }

                                    </Badge>

                                </OverlayTrigger>

                            </Col>

                            <Col xs='8' sm='9' md='7' xl='4'>

                                <div className='fs-6 line-height-1'>

                                    { getLivestreamTitle( this.props.item ) }

                                </div>

                                <div>

                                    { this.props.item.CompetitionName && <span className='text-primary me-3'><Icon icon='trophy' /> { this.props.item.CompetitionName }</span> }

                                    { this.props.item.CameraDescription && <span className='text-success me-3'><Icon icon='map-marker-alt' /> { this.props.item.CameraDescription }</span> }

                                    { this.props.item.LocationCameras && this.props.item.LocationCameras.length > 0 && <span className='text-info'><Icon icon='video' /> { this.props.item.LocationCameras[ 0 ].CameraSupplier }</span> }

                                </div>

                            </Col>

                            <Col xs='12' sm='4' md='3' xl='2' className='fs-6 py-2'>

                                <div className='d-flex align-items-end justify-content-center'>

                                    <LabeledSwitch checked={ this.props.item.Broadcastable } onChange={ e => this.setLivestreamStatus( e ) } disabled={ this.state.isSettingLivestreamStatus || ! hasPrivilege( this.props.user, PRIV_SET_GAME_STATUS ) } label={ _( 'Broadcastable' ) } />

                                    {

                                        this.props.item.showIgnoreOverlay ?

                                            ignoreOverlaysSwitch

                                        :

                                            <OverlayTrigger overlay={ <Tooltip>{ _( 'No automatic Overlay' ) }</Tooltip> }><span>{ ignoreOverlaysSwitch }</span></OverlayTrigger>

                                    }

                                    { hasPrivilege( this.props.user, PRIV_VIEW_STREAM_INFO ) && <Button variant='link' onClick={ () => this.toggleInfoModal() }><Icon icon='key' /></Button> }

                                    { hasPrivilege( this.props.user, PRIV_VIEW_STREAM_PLAYER ) && this.props.item.OverlayHlsOutput ? <Button variant='link' onClick={ () => this.togglePlayerModal() }><Icon icon='circle-play' regular fw /></Button> : <Button variant='link' disabled><Icon fw /></Button> }

                                </div>

                            </Col>

                            <Col xs='12' sm='8' md='6' xl='3' className='py-2 text-center'>

                                { getIncomingStreamStatusLabel( this.props.item.IncomingStreamStatus || 'Unknown' ) }

                                { getEventStatusLabel( this.props.item.CameraMatchId ) }

                                { getCameraStatusLabel( this.props.item.CameraInfo ? this.props.item.CameraInfo.Status : 'Unknown' ) }

                            </Col>

                            <Col xs='12' md='6' xl='2' className='py-2'>

                                <div className='d-flex align-items-center justify-content-center'>

                                    {

                                        canViewGameNotes && (

                                            this.props.item.SupportNotes ?

                                                <OverlayTrigger overlay={<Popover><Popover.Body>{ this.props.item.SupportNotes }</Popover.Body></Popover>}>

                                                    { this.renderNotesButton( true ) }

                                                </OverlayTrigger>

                                            :

                                                this.renderNotesButton()

                                        )

                                    }

                                    <DropdownButton title={ _( 'Optionen' ) } align='end' variant='default'>

                                        { canSetGamePrice          && <Dropdown.Item as='button' type='button' onClick={ () => this.togglePriceModal()                           }>{ _( 'Preis setzen'                               ) }</Dropdown.Item> }
                                        { canViewGameNotes         && <Dropdown.Item as='button' type='button' onClick={ () => this.toggleNotesModal()                           }>{ _( 'Notizen erweitern'                          ) }</Dropdown.Item> }
                                        { canViewStreamOutputInfo  && <Dropdown.Item as='button' type='button' onClick={ () => this.toggleOutputInfoModal()                      }>{ _( 'Stream Output Infos'                        ) }</Dropdown.Item> }
                                                                      <Dropdown.Item as='button' type='button' onClick={ () => this.toggleLocationContactDetailsModal()          }>{ _( 'Kontaktinformationen'                       ) }</Dropdown.Item>
                                                                      <Dropdown.Item as='button' type='button' onClick={ () => this.toggleVideoListModal()                       }>{ _( 'Videos anzeigen'                            ) }</Dropdown.Item>
                                        { this.props.item.LosLink  && <Dropdown.Item target='_blank' href={ this.props.item.LosLink                                              }>{ _( 'Streamlink öffnen'                          ) }</Dropdown.Item> }

                                        { ( canIgnoreActivity || canResetLivestream || canCancelLivestream || canStopLivestream || canUpdateLivestreamTitle || canUploadStreamVideoFile ) && <Dropdown.Divider /> }

                                        { canIgnoreActivity        && <Dropdown.Item as='button' type='button' onClick={ () => this.executeStreamAction( 'setIgnoreInactivity' ) }>{ _( 'Inaktivität ignorieren'                     ) }</Dropdown.Item> }
                                        { canResetLivestream       && <Dropdown.Item as='button' type='button' onClick={ () => this.executeStreamAction( 'resetStream'         ) }>{ _( 'Stream erneut starten'                      ) }</Dropdown.Item> }
                                        { canCancelLivestream      && <Dropdown.Item as='button' type='button' onClick={ () => this.executeStreamAction( 'CancelStream'        ) }>{ _( 'Stream canceln (Recording NICHT speichern)' ) }</Dropdown.Item> }
                                        { canStopLivestream        && <Dropdown.Item as='button' type='button' onClick={ () => this.executeStreamAction( 'StopStream'          ) }>{ _( 'Stream stoppen (Recording speichern)'       ) }</Dropdown.Item> }
                                        { canUpdateLivestreamTitle && <Dropdown.Item as='button' type='button' onClick={ () => this.updateStreamName(                          ) }>{ _( 'Streamtitel aktualisieren'                  ) }</Dropdown.Item> }
                                        { canUploadStreamVideoFile && <Dropdown.Item as='button' type='button' onClick={ () => this.toggleVideoFileUploadModal(                ) }>{ _( 'Videodatei hochladen'                       ) }</Dropdown.Item> }

                                    </DropdownButton>

                                </div>

                            </Col>

                        </Row>

                    </Card.Body>

                    { this.state.isExecutingStreamAction && <div className='item-overlay'><div><Icon icon='spinner' spin /> { this.state.streamActionInfinitive }</div></div> }

                    { this.state.hasExecutingStreamActionError && <div className='item-overlay'><div><Icon icon='exclamation-triangle' /> { _( 'Fehler!' ) }</div></div> }

                </Card>

                <LivestreamNotesModal livestream={ this.props.item } show={ this.state.notesModalOpen } onHide={ () => this.toggleNotesModal() } token={ this.props.token } onChange={ ( SupportNotes, StreamQuality ) => this.changeItem( { SupportNotes, StreamQuality } ) } user={ this.props.user } />

                <LivestreamPriceModal livestream={ this.props.item } show={ this.state.priceModalOpen } onHide={ () => this.togglePriceModal() } token={ this.props.token } onChange={ CostInEur => this.changeItem( { CostInEur } ) } />

                <LivestreamOutputInfoModal livestream={ this.props.item } show={ this.state.outputInfoModalOpen } onHide={ () => this.toggleOutputInfoModal() } />

                <LivestreamInfoModal livestream={ this.props.item } show={ this.state.infoModalOpen } onHide={ () => this.toggleInfoModal() } />

                <LivestreamPlayerModal livestream={ this.props.item } show={ this.state.playerModalOpen } onHide={ () => this.togglePlayerModal() } />

                <ConfirmationModal show={ this.state.confirmationModalOpen } onHide={ () => this.hideConfirmationModal() } onConfirm={ () => this.confirmStreamAction() } title={ this.state.confirmationModalTitle } heading={ this.state.confirmationModalHeading } text={ this.state.confirmationModalText } />

                <LivestreamLocationContactDetailsModal livestream={ this.props.item } show={ this.state.locationContactDetailsModalOpen } onHide={ () => this.toggleLocationContactDetailsModal() } />

                <LivestreamVideoFileUploadModal livestream={ this.props.item } channelUrl={ this.props.item.ChannelUrl } show={ this.state.videoFileUploadModalOpen } onHide={ () => this.toggleVideoFileUploadModal() } onUpload={ e => this.addVideo( e ) } />

                <LivestreamVideoListModal videos={ this.props.item.videos } channelUrl={ this.props.item.ChannelUrl } show={ this.state.videoListModalOpen } onHide={ () => this.toggleVideoListModal() } />

            </>

        )

    }

}

export default Livestream