import React from 'react'
import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap'

class AddLeagueModal extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            addingLeagueSuccessful: false,
            isAddingLeague:         false,
            losLeague:              null,
            losLeagues:             null,

        }

    }

    componentDidMount() {

        this.loadLOSLeagues()

    }

    addLeague() {

        this.setState( { addingLeagueSuccessful: false, isAddingLeague: true }, () => {

            ajax( getDataUrl( 'api/support/setStreamableLeague' ), { token: this.props.token, leagueId: this.state.losLeague, streamOverlays: true }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && this.setState( { addingLeagueSuccessful: true }, () => this.props.onAdd( this.state.losLeagues.find( e => e.Id === this.state.losLeague ) ) ) )
                .finally( () => this.setState( { isAddingLeague: false } ) )

        } )

    }

    changeLeague( e ) {

        this.setState( { losLeague: Number( e.target.value ) } )

    }

    closeModal() {

        this.setState( {

            addingLeagueSuccessful: false,
            isAddingLeague:         false,
            losLeague:              null,

        }, () => this.props.onHide() )

    }

    loadLOSLeagues() {

        ajax( getDataUrl( 'api/support/getLOSLeagues' ), { token: this.props.token }, { method: 'POST' } ).then( e => e.StatusId > 0 && this.setState( { losLeagues: e.Data, losLeague: e.Data && e.Data.length ? e.Data[ 0 ].Id : null } ) )

    }

    render() {

        return (

            <Modal show={ this.props.show } onHide={ () => this.closeModal() } centered backdrop='static'>

                <Modal.Header>

                    <Modal.Title>{ _( 'Liga hinzufügen' ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    <fieldset disabled={ this.state.isAddingLeague || this.state.addingLeagueSuccessful }>

                        <Form.Group as={ Row } className='mb-3' controlId='losLeague'>

                            <Form.Label column sm='4'>{ _( 'LOS Liga' ) }</Form.Label>

                            <Col sm='8'>

                                <Form.Select value={ this.state.losLeague || '' } onChange={ e => this.changeLeague( e ) } disabled={ this.state.losLeagues === null }>

                                    {

                                        this.state.losLeagues ?

                                            this.state.losLeagues.map( ( e, i ) => ( ! this.props.leagues || ! this.props.leagues.find( league => league.Id === e.Id ) ) && <option value={ e.Id } key={ i }>{ e.Name }</option> )

                                        :

                                            <option>{ _( 'Daten werden geladen...' ) }</option>

                                    }

                                </Form.Select>

                            </Col>

                        </Form.Group>

                    </fieldset>

                    { this.state.addingLeagueSuccessful && <Alert variant='success'><Icon icon='check' /> { _( 'Liga wurde hinzugefügt.' ) }</Alert> }

                </Modal.Body>

                <Modal.Footer>

                    {

                        this.state.addingLeagueSuccessful ?

                            <Button onClick={ () => this.closeModal() } variant='secondary'>{ _( 'Schließen' ) }</Button>

                        :

                            <>

                                <Button onClick={ () => this.closeModal() } disabled={ this.state.isAddingLeague } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                                <Button onClick={ () => this.addLeague() } disabled={ this.state.isAddingLeague }>{ _( 'Hinzufügen' ) }</Button>

                            </>

                    }

                </Modal.Footer>

            </Modal>

        )

    }

}

export default AddLeagueModal