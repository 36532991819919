import React from 'react'
import { Modal } from 'react-bootstrap'
import { getLivestreamTitle } from '../../util'
import LivestreamPlayer from './LivestreamPlayer'

class LivestreamPlayerModal extends React.Component {

    render() {

        return (

            <Modal show={ this.props.show } onHide={ () => this.props.onHide() } fullscreen={ true }>

                <Modal.Header closeButton>

                    <Modal.Title>{ getLivestreamTitle( this.props.livestream ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    <LivestreamPlayer livestream={ this.props.livestream } />

                </Modal.Body>

            </Modal>

        )

    }

}

export default LivestreamPlayerModal