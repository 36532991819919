import { translate as _ } from '@hockeydata/skynet'
import { findTextInObject } from '../../util'
import User from '../elements/User'
import Page from '../templates/Page'
import { Button } from 'react-bootstrap'
import CreateUserModal from '../elements/CreateUserModal'

class Users extends Page {

    constructor( props ) {

        super( props )

        this.itemComponent = User
        this.pageIcon      = 'users'
        this.pageTitle     = _( 'Benutzer' )

    }

    checkItem( item ) {

        return ! this.props.textFilter || findTextInObject( item, this.props.textFilter, [ 'Mail' ] )

    }

    getDataUrl() {

        return 'api/Account/Get?token=' + this.props.token

    }

    toggleCreateUserModal() {

        this.setState( { createUserModalOpen: ! this.state.createUserModalOpen } )

    }

    renderFooter() {

        return (

            <CreateUserModal show={ this.state.createUserModalOpen } onHide={ () => this.toggleCreateUserModal() } token={ this.props.token } />

        )

    }

    renderSubheaderBlock() {

        return (

            <Button onClick={ () => this.toggleCreateUserModal() }>{ _( 'Neuer Benutzer' ) }</Button>

        )

    }

}

export default Users