import React from 'react'
import { ajax, getDataUrl, translate as _ } from '@hockeydata/skynet'
import { Button, FloatingLabel, Form, Modal } from 'react-bootstrap'
import { getLivestreamTitle, hasPrivilege } from '../../util'
import { PRIV_SAVE_SUPPORT_FIELDS } from '../../util/constants'

class LivestreamNotesModal extends React.Component {

    #streamQualityValues
    // #viewerInformationValues

    constructor( props ) {

        super( props )

        this.state = {

            isSaving:      false,
            show:          false,
            supportNotes:  '',
            streamQuality: '',

        }

        this.#streamQualityValues     = [ '', _( 'Super' ), _( 'Schlecht - Bitte refundieren' ), _( 'Streamabbruch - Bitte Gutscheincodes versenden' ) ]
        // this.#viewerInformationValues = [ '', _( 'Alles okay' ), _( 'Kamera derzeit offline' ), _( 'Streaming ungewiss' ), _( 'Wir arbeiten an technischer Lösung' ) ]

    }

    componentDidUpdate() {

        this.props.show !== this.state.show && this.setState( {

            show:          this.props.show,
            supportNotes:  this.props.livestream.SupportNotes  || '',
            streamQuality: this.props.livestream.StreamQuality || '',

        } )

    }

    change( e ) {

        this.setState( { [ e.target.name ]: e.target.value } )

    }

    closeModal() {

        this.setState( { isSaving: false }, () => this.props.onHide() )

        return true

    }

    save() {

        const data = {

            streamId:              this.props.livestream.Id,
            eventRecordingStatus:  '',
            streamQuality:         this.state.streamQuality,
            awaitingAnotherStream: '',
            streamEndedStatus:     '',
            overlayCheck:          '',
            supportNotes:          this.state.supportNotes,
            token:                 this.props.token,

        }

        this.setState( { isSaving: true }, () =>

            ajax( getDataUrl( 'api/support/saveSupportFields' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && this.closeModal() && this.props.onChange( this.state.supportNotes, this.state.streamQuality ) )
                .finally( () => this.setState( { isSaving: false } ) )

        )

    }

    render() {

        return (

            <Modal show={ this.state.show } onHide={ () => this.closeModal() } centered backdrop='static'>

                <Modal.Header closeButton>

                    <Modal.Title>{ getLivestreamTitle( this.props.livestream ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    <fieldset disabled={ this.state.isSaving || ! hasPrivilege( this.props.user, PRIV_SAVE_SUPPORT_FIELDS ) }>

                        <FloatingLabel label={ _( 'Notizen' ) } className='mb-3'>

                            <Form.Control
                                as='textarea'
                                autoFocus
                                name='supportNotes'
                                onChange={ e => this.change( e ) }
                                style={ { height: '150px' } }
                                value={ this.state.supportNotes }
                            />

                        </FloatingLabel>

                        <FloatingLabel label={ _( 'Stream Qualität' ) } className='mb-3'>

                            <Form.Select name='streamQuality' onChange={ e => this.change( e ) } value={ this.state.streamQuality }>

                                { this.#streamQualityValues.map( ( e, i ) => <option value={ e } key={ i }>{ e }</option> ) }

                            </Form.Select>

                        </FloatingLabel>

                        {/* <FloatingLabel label={ _( 'Zuschauer Information' ) } className='mb-3'>

                            <Form.Select name='viewerInformation' onChange={ e => this.change( e ) } value={ this.state.viewerInformation }>

                                { this.#viewerInformationValues.map( ( e, i ) => <option value={ e } key={ i }>{ e }</option> ) }

                            </Form.Select>

                        </FloatingLabel> */}

                    </fieldset>

                </Modal.Body>

                <Modal.Footer>

                    <Button onClick={ () => this.closeModal() } disabled={ this.state.isSaving } variant='secondary'>{ _( 'Schließen' ) }</Button>

                    { hasPrivilege( this.props.user, PRIV_SAVE_SUPPORT_FIELDS ) && <Button onClick={ () => this.save() } disabled={ this.state.isSaving }>{ _( 'Speichern' ) }</Button> }

                </Modal.Footer>

            </Modal>

        )

    }

}

export default LivestreamNotesModal