import { translate as _ } from '@hockeydata/skynet'
import { Button } from 'react-bootstrap'
import { findTextInObject, sortByName } from '../../util'
import AddLeagueModal from '../elements/AddLeagueModal'
import League from '../elements/League'
import Page from '../templates/Page'

class Leagues extends Page {

    constructor( props ) {

        super( props )

        this.itemComponent = League
        this.pageIcon      = 'trophy'
        this.pageTitle     = _( 'Ligen' )

        this.state = {

            addLeagueModalOpen: false,

        }

    }

    checkItem( item ) {

        return ! this.props.textFilter || findTextInObject( item, this.props.textFilter, [ 'Name' ] )

    }

    closeAddLeagueModal() {

        this.setState( { addLeagueModalOpen: false } )

    }

    getDataRequestOptions() {

        return { method: 'POST' }

    }

    getDataRequestParameters() {

        return { token: this.props.token }

    }

    getDataUrl() {

        return 'api/support/getStreamableLeagues'

    }

    openAddLeagueModal() {

        this.setState( { addLeagueModalOpen: true } )

    }

    sortData( data ) {

        sortByName( data )

    }

    renderFooter() {

        return (

            <AddLeagueModal
                leagues={ this.state.data }
                onAdd={ item => this.addItem( item ) }
                onHide={ () => this.closeAddLeagueModal() }
                show={ this.state.addLeagueModalOpen }
                token={ this.props.token }
            />

        )

    }

    renderSubheaderBlock() {

        return (

            <Button onClick={ () => this.openAddLeagueModal() }>{ _( 'Liga hinzufügen' ) }</Button>

        )

    }

}

export default Leagues