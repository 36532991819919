import React from 'react'
import { translate as _ } from '@hockeydata/skynet'
import { Button, Modal } from 'react-bootstrap'
import VideoList from './VideoList'

class LivestreamVideoListModal extends React.Component {

    render() {

        return (

            <Modal show={ this.props.show } onHide={ () => this.props.onHide() } centered fullscreen>

                <Modal.Header closeButton>

                    <Modal.Title>{ _( 'Videos' ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    <VideoList videos={ this.props.videos } channelUrl={ this.props.channelUrl } />

                </Modal.Body>

                <Modal.Footer>

                    <Button onClick={ () => this.props.onHide() } variant='secondary'>{ _( 'Schließen' ) }</Button>

                </Modal.Footer>

            </Modal>

        )

    }

}

export default LivestreamVideoListModal