import { actionTypes } from '@hockeydata/skynet'
import { getDefaultDateRange } from '../util'
import { LIVESTREAMS_STATUS_FILTER_BROADCAST, PAYMENTS_STATUS_FILTER_ALL } from '../util/constants'
import { SET_DATE_RANGE, SET_UI_VALUE } from './constants'

const initialStateUi = {

    dateRangeFromLivestreams: getDefaultDateRange().from,
    dateRangeToLivestreams:   getDefaultDateRange().to,
    dateRangeFromPayments:    getDefaultDateRange( true ).from,
    dateRangeToPayments:      getDefaultDateRange( true ).to,
    statusFilterLivestreams:  LIVESTREAMS_STATUS_FILTER_BROADCAST,
    statusFilterPayments:     PAYMENTS_STATUS_FILTER_ALL,
    textFilterLeagues:        '',
    textFilterLivestreams:    '',
    textFilterPayments:       '',
    textFilterSuspensions:    '',
    textFilterUsers:          '',
    textFilterWorkspaces:     '',

}

export const ui = ( state = initialStateUi, action ) => {

    switch ( action.type ) {

        case actionTypes.LOGOUT: {

            return initialStateUi

        }

        case SET_DATE_RANGE: {

            return { ...state, [ action.payload.keyFrom ]: action.payload.from, [ action.payload.keyTo ]: action.payload.to }

        }

        case SET_UI_VALUE: {

            return { ...state, [ action.payload.key ]: action.payload.value }

        }

        default:

            return state

    }

}