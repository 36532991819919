import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import ConfirmationModal from './ConfirmationModal'

class League extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            confirmationModalOpen:  false,
            hasRemovingLeagueError: false,
            isRemovingLeague:       false,

        }

    }

    removeLeague() {

        this.setState( { confirmationModalOpen: false, hasRemovingLeagueError: false, isRemovingLeague: true }, () => setTimeout( () => {

            const err = () => this.setState( { hasRemovingLeagueError: true }, () => setTimeout( () => this.setState( { hasRemovingLeagueError: false } ), 3000 ) )

            ajax( getDataUrl( 'api/support/setStreamableLeague' ), { token: this.props.token, leagueId: this.props.item.Id, streamOverlays: false }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? this.removeItem( this.props.item ) : err() )
                .catch( () => err() )
                .finally( () => this.setState( { isRemovingLeague: false } ) )

        }, 1000 ) )

    }

    toggleConfirmationModal() {

        this.setState( { confirmationModalOpen: ! this.state.confirmationModalOpen } )

    }

    render() {

        return (

            <>

                <Card className='mb-2 league-item'>

                    <Card.Body>

                        <Row className='align-items-center'>

                            <Col className='fs-6'>

                                { this.props.item.Name }

                            </Col>

                            <Col xs='4' sm='3' md='2' xl='2' className='text-right'>

                                <Button onClick={ () => this.toggleConfirmationModal() } variant='default'>{ _( 'Entfernen' ) }</Button>

                            </Col>

                        </Row>

                    </Card.Body>

                    { this.state.isRemovingLeague && <div className='item-overlay'><div><Icon icon='spinner' spin /> { _( 'Liga wird entfernt...' ) }</div></div> }

                    { this.state.hasRemovingLeagueError && <div className='item-overlay'><div><Icon icon='exclamation-triangle' /> { _( 'Fehler!' ) }</div></div> }

                </Card>

                <ConfirmationModal show={ this.state.confirmationModalOpen } onHide={ () => this.toggleConfirmationModal() } onConfirm={ () => this.removeLeague() } title={ _( 'Liga entfernen' ) } text={ _( 'Bist du sicher, dass du diese Liga entfernen möchtest?' ) } />

            </>

        )

    }

}

export default League