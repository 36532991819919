import React from 'react'
import { BITMOVIN_PLAYER_KEY } from '../../util/constants'
import { Icon } from '@hockeydata/skynet'

class LivestreamPlayer extends React.Component {

    #dom

    constructor( props ) {

        super( props )

        this.state = {

            hasLoadingError: false,
            isLoading:       true,
            player:          null,

        }

        this.#dom = {

            player: React.createRef(),

        }

    }

    componentDidMount() {

        this.setupPlayer()

    }

    componentWillUnmount() {

        this.destroyPlayer()

    }

    destroyPlayer() {

        if ( this.state.player ) {

            this.state.player.destroy()

            this.setState( { player: null } )

        }

    }

    setupPlayer() {

        const player = new window.bitmovin.player.Player( this.#dom.player.current, { key: BITMOVIN_PLAYER_KEY } )

        player
            .load( { hls: this.props.livestream.OverlayHlsOutput } )
            .then(
                () => this.setState( { isLoading: false, player } ),
                () => this.setState( { isLoading: false, hasLoadingError: true } )
            )

    }

    render() {

        return (

            <>

                <div ref={ this.#dom.player } style={ this.state.isLoading ? { display: 'none' } : {} }></div>

                { this.state.hasLoadingError && <div className='livestream-player-status'><div><Icon icon='video-slash' size='4' /></div></div> }

                { this.state.isLoading && <div className='livestream-player-status'><div><Icon icon='spinner' size='4' spin /></div></div> }

            </>

        )

    }

}

export default LivestreamPlayer