import { SET_DATE_RANGE, SET_UI_VALUE } from './constants'

export const setDateRange = ( from, to, keyFrom, keyTo ) => {

    return {

        type:    SET_DATE_RANGE,
        payload: { from, to, keyFrom, keyTo },

    }

}

export const setUiValue = ( key, value ) => {

    return {

        type:    SET_UI_VALUE,
        payload: { key, value },

    }

}