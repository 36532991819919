import React from 'react'
import { translate as _ } from '@hockeydata/skynet'
import Video from './Video'

class VideoList extends React.Component {

    render() {

        return (

            this.props.videos && this.props.videos.length ?

                this.props.videos.map( ( item, index ) => <Video key={ index } video={ item } channelUrl={ this.props.channelUrl } /> )

            :

                <p className='text-center'>{ _( 'Keine Videos vorhanden.' ) }</p>

        )

    }

}

export default VideoList