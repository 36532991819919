import React from 'react'
import { translate as _ } from '@hockeydata/skynet'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { getLivestreamTitle } from '../../util'
import CtCInput from './CtCInput'

class LivestreamOutputInfoModal extends React.Component {

    renderInput( options ) {

        return (

            <Form.Group as={ Row } className='mb-3' controlId={ options.id }>

                <Form.Label column sm='3'>{ options.label }</Form.Label>

                <Col sm='9'>

                    <CtCInput value={ this.props.livestream[ options.key ] } />

                </Col>

            </Form.Group>

        )

    }

    render() {

        return (

            <Modal show={ this.props.show } onHide={ () => this.props.onHide() } centered>

                <Modal.Header closeButton>

                    <Modal.Title>{ getLivestreamTitle( this.props.livestream ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    <h4>{ _( 'Raw-Streams' ) }</h4>

                    { this.renderInput( { id: 'rawRtspStream', label: _( 'rtsp-Stream' ), key: 'RawRtspOutput' } ) }

                    { this.renderInput( { id: 'rawHlsStream', label: _( 'hls-Stream' ), key: 'RawHlsOutput' } ) }

                    <h4 className='mt-5'>{ _( 'Overlay-Streams' ) }</h4>

                    { this.renderInput( { id: 'hlsStream', label: _( 'hls-Stream' ), key: 'OverlayHlsOutput' } ) }

                </Modal.Body>

                <Modal.Footer>

                    <Button onClick={ () => this.props.onHide() }>{ _( 'Schließen' ) }</Button>

                </Modal.Footer>

            </Modal>

        )

    }

}

export default LivestreamOutputInfoModal