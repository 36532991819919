import { Icon, translate as _ } from '@hockeydata/skynet'
import React from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { createDate } from '../../util/date'

class ReloadStatusButton extends React.Component {

    #reloadInterval
    #timer

    constructor( props ) {

        super( props )

        this.#reloadInterval = 60

        this.state = {

            value: this.#reloadInterval,

        }

    }

    componentDidMount() {

        this.#timer = setInterval( () => this.updateValue(), 1000 )

    }

    componentWillUnmount() {

        clearInterval( this.#timer )

    }

    updateValue() {

        let value = Math.floor( this.#reloadInterval - ( createDate() - this.props.lastUpdate ) / 1000 )

        if ( value < 1 ) {

            value = 0

            this.props.onClick()

        }

        this.setState( { value } )

    }

    render() {

        const iconProps = {}

        if ( this.props.isLoading ) {

            iconProps.spin = true

        }

        return (

            <OverlayTrigger overlay={ <Tooltip>{ this.state.hasError ? _( 'Status konnten nicht aktualisiert werden' ) : _( 'Status aktualisieren' ) }</Tooltip> }>

                <Button
                    variant={ this.props.hasError ? 'warning' : 'secondary' }
                    onClick={ this.props.onClick }
                    disabled={ this.props.disabled }
                >

                    <Icon icon={ this.state.hasError ? 'exclamation-triangle' : 'refresh' } { ...iconProps } />

                    { ! this.props.disabled && ! this.props.hasError && ' ' + this.state.value }

                </Button>

            </OverlayTrigger>

        )

    }

}

export default ReloadStatusButton