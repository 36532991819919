import { translate as _ } from '@hockeydata/skynet'
import { Button, Col, Form, Row } from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { findTextInObject, getDateRangePickerLocale } from '../../util'
import { PAYMENTS_STATUS_FILTER_ALL, PAYMENTS_STATUS_FILTER_NONE, PAYMENT_STATUS_CANCELLED, PAYMENT_STATUS_DONE, PAYMENT_STATUS_PENDING } from '../../util/constants'
import Payment from '../elements/Payment'
import Page from '../templates/Page'

class Payments extends Page {

    constructor( props ) {

        super( props )

        this.dataSortKey         = 'CreatedAt'
        this.itemComponent       = Payment
        this.pageIcon            = 'money-bill'
        this.pageTitle           = _( 'Payments' )
        this.statusFilterOptions = [

            { label: _( 'Alle'      ), value: PAYMENTS_STATUS_FILTER_ALL  },
            { label: _( 'Bezahlt'   ), value: PAYMENT_STATUS_DONE         },
            { label: _( 'Offen'     ), value: PAYMENT_STATUS_PENDING      },
            { label: _( 'Storniert' ), value: PAYMENT_STATUS_CANCELLED    },
            { label: _( '[kein]'    ), value: PAYMENTS_STATUS_FILTER_NONE },

        ]

    }

    checkItem( item ) {

        if ( this.props.textFilter && ! findTextInObject( item, this.props.textFilter, [ 'Email', 'Title' ] ) ) {

            return false

        }

        if ( this.props.statusFilter === PAYMENTS_STATUS_FILTER_ALL ) {

            return true

        }

        if ( this.props.statusFilter === PAYMENTS_STATUS_FILTER_NONE ) {

            return item.PaymentStatus === null

        }

        return this.props.statusFilter === item.PaymentStatus

    }

    getDataUrl() {

        return 'api/Overview/GetPayments?token=' + this.props.token + '&from=' + this.props.dateRangeFrom.toJSON() + '&to=' + this.props.dateRangeTo.toJSON()

    }

    renderFilters() {

        return (

            <div className='data-filter'>

                <Row className='align-items-center mb-3 px-3'>

                    <Col className='mb-1' xs='12' sm='6' md='4' lg='3'>

                        <DateRangePicker initialSettings={ {

                            endDate:   this.props.dateRangeTo,
                            locale:    getDateRangePickerLocale(),
                            startDate: this.props.dateRangeFrom,

                        } } onApply={ ( e, f ) => this.handleDateRangeChange( e, f ) }>

                            <Form.Control />

                        </DateRangePicker>

                    </Col>

                    <Col className='mb-1' xs='12' sm='6' md={ { span: 4, offset: 4 } } lg={ { span: 3, offset: 6 } }>

                        <div className='d-grid'>

                            <Button onClick={ () => this.loadData() }>{ _( 'Laden' ) }</Button>

                        </div>

                    </Col>

                </Row>

                <Row className='align-items-center mb-3 px-3'>

                    <Col className='mb-1' xs='12' sm='6' md='4' lg='3'>

                        <Form.Select onChange={ e => this.props.onStatusFilterChange( e.target.value ) } value={ this.props.statusFilter }>

                            { this.statusFilterOptions && this.statusFilterOptions.map( ( e, i ) => <option value={ e.value } key={ i }>{ e.label }</option> ) }

                        </Form.Select>

                    </Col>

                    <Col className='mb-1' xs='12' sm='6' md='8' lg='9'>

                        <Form.Control onChange={ e => this.props.onTextFilterChange( e.target.value ) } value={ this.props.textFilter } placeholder={ _( 'Suchergebnis filtern' ) } />

                    </Col>

                </Row>

            </div>

        )

    }

}

export default Payments