import React from 'react'
import { Button, Card, Col, Image, Row } from 'react-bootstrap'
import { Icon, translate as _ } from '@hockeydata/skynet'
import { formatDuration } from '../../util'

class Video extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            downloading:      false,
            errorDownloading: false,

        }

    }

    downloadVideo() {

        this.setState( { errorDownloading: false }, () => {

            const err = () => this.setState( { errorDownloading: true }, () => setTimeout( () => this.setState( { errorDownloading: false } ), 3000 ) )

            fetch( this.getDownloadUrl() + this.props.video.Originalname )
                .then( e => e.text() )
                .then( e => window.open( e ) )
                .catch( err )
                .finally( () => this.setState( { downloading: false } ) )

        } )

    }

    getDownloadUrl() {

        return this.props.channelUrl ? 'https://' + this.props.channelUrl + '/api/video/download/' : null

    }

    render() {

        return (

            <Card className='mb-2'>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col xs='12' md='2' lg='1'>

                            <Image src={ this.props.video.Thumbnail } thumbnail />

                        </Col>

                        <Col xs='10' md='7' lg='8' className='pt-3 pt-md-0'>

                            <h3>{ this.props.video.Title }</h3>

                            { this.props.video.Description && <p className='text-muted'>{ this.props.video.Description }</p> }

                        </Col>

                        <Col xs='2' md='1' className='text-end'>

                            { formatDuration( this.props.video.Duration ) }

                        </Col>

                        <Col xs='12' md='2'>

                            <Button variant='outline-secondary' size='lg' onClick={ () => this.downloadVideo( this.props.video ) } className='d-block w-100' disabled={ this.state.downloading }>

                                {

                                    this.state.downloading ?

                                        <Icon icon='spinner' spin />

                                    :

                                        <>

                                            <Icon icon='download' />

                                            <span className='ms-3 d-md-none'>{ _( 'Download' ) }</span>

                                        </>

                                }

                            </Button>

                        </Col>

                    </Row>

                </Card.Body>

                { this.state.errorDownloading && <div className='item-overlay'><div><Icon icon='exclamation-triangle' /> { _( 'Fehler!' ) }</div></div> }

            </Card>

        )

    }

}

export default Video