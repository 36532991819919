import React from 'react'
import { Icon, translate as _ } from '@hockeydata/skynet'
import { Alert, Button, Col, FloatingLabel, Form, Modal, ProgressBar, Row } from 'react-bootstrap'
import { getLivestreamTitle } from '../../util'
import { getFileExtension, getReadableFileSize, uploadFile } from '../../util/file'

class LivestreamVideoFileUploadModal extends React.Component {

    #maxChunkSize      = 10242880
    #maxFileSize       = 5000000000
    #acceptedFileTypes = [ 'mp4', 'mov', 'webm', 'wmv', 'avi', 'flv' ]

    constructor( props ) {

        super( props )

        this.state = this.getDefaultState()

    }

    componentDidUpdate() {

        this.props.show !== this.state.show && this.setState( {

            ...this.getDefaultState(),

            show:  this.props.show,
            title: this.props.livestream ? getLivestreamTitle( this.props.livestream ) : '',

        } )

    }

    change( e ) {

        this.setState( { [ e.target.name ]: e.target.value } )

    }

    getDefaultState() {

        return {

            activeJunkIndex: 0,
            error:           false,
            isUploading:     false,
            show:            false,
            file:            null,
            junks:           [],
            success:         false,
            title:           '',
            uploadedFile:    null,

        }

    }

    getUploadUrl() {

        return this.props.channelUrl ? 'https://' + this.props.channelUrl + '/api/videos/chunk' : null

    }

    selectFile() {

        const input = document.createElement( 'input' )

        input.type     = 'file'
        input.accept   = this.#acceptedFileTypes.map( e => '.' + e ).join( ',' )
        input.onchange = e => this.setFile( e.target.files[ 0 ] )

        input.click()

    }

    setFile( file ) {

        if ( file.size > this.#maxFileSize ) {

            return alert( _( 'Die Datei ist zu groß.' ) )

        }

        this.setState( { file } )

    }

    upload() {

        this.setState( { isUploading: true }, () => {

            let startPointer = 0
            const junks      = []

            while ( startPointer < this.state.file.size ) {

                const file    = this.state.file.slice( startPointer, startPointer + this.#maxChunkSize )
                startPointer += this.#maxChunkSize

                junks.push( { file, progress: 0 } )

            }

            this.setState( { junks }, () => this.uploadChunk( 0 ) )

        } )

    }

    updateJunk( i, junk, callback ) {

        const junks = [ ...this.state.junks ]
        junks[ i ]  = { ...this.state.junks[ i ], ...junk }

        this.setState( { junks }, callback )

    }

    uploadChunk( i ) {

        const err  = () => this.setState( { error: true, isUploading: false } )
        const junk = this.state.junks[ i ]

        junk ?

            this.setState( { activeJunkIndex: i }, () => {

                const fileExtension = getFileExtension( this.state.file.name )
                const formData      = new FormData()
                const onProgress    = progress => this.updateJunk( i, { progress } )
                const tmpName       = ( this.props.livestream ? this.props.livestream.videos.length + 1 : new Date().getTime() ) + '.' + fileExtension
                const workspace     = this.props.livestream ? this.props.livestream.Urlslug : this.props.workspaceUrlSlug

                formData.append( 'tmp_name',  tmpName                           )
                formData.append( 'workspace', workspace                         )
                formData.append( 'filename',  this.state.title                  )
                formData.append( 'size',      junk.file.size                    )
                formData.append( 'extension', fileExtension                     )
                formData.append( 'part',      i                                 )
                formData.append( 'is_last',   i + 1 === this.state.junks.length )
                formData.append( 'file',      junk.file, tmpName                )

                this.props.livestream && formData.append( 'stream_id', this.props.livestream.LiveStreamId )

                uploadFile( { url: this.getUploadUrl(), auth: this.props.uploadAuth, formData, onProgress } )
                    .then( e => e.uploaded ? this.uploadChunk( i + 1 ) : ( e.data && e.data.id ) ? this.setState( { uploadedFile: e.data }, () => this.uploadChunk( i + 1 ) ) : err() )
                    .catch( err )

            } )

        :

            this.setState( { isUploading: false, success: true }, () => this.props.onUpload && this.props.onUpload( this.state.uploadedFile ) )

    }

    renderForm() {

        return (

            <>

                <FloatingLabel label={ _( 'Titel' ) }>

                    <Form.Control onChange={ e => this.setState( { title: e.target.value } ) } value={ this.state.title } disabled={ this.props.livestream } />

                </FloatingLabel>

                {

                    this.state.file ?

                        <div className='text-center mt-5 lead text-break'>

                            { this.state.file.name } ({ getReadableFileSize( this.state.file.size ) })

                        </div>

                    :

                        <>

                            <div className='text-center mt-5'>

                                <Button onClick={ () => this.selectFile() } size='lg'>{ _( 'Datei auswählen' ) }</Button>

                            </div>

                            <Row className='mt-3'>

                                <Col>{ _( 'Erlaubte Dateitypen:' ) }</Col>

                                <Col>{ this.#acceptedFileTypes.join( ', ' ) }</Col>

                            </Row>

                            <Row className='mt-1'>

                                <Col>{ _( 'Maximale Dateigröße:' ) }</Col>

                                <Col>{ getReadableFileSize( this.#maxFileSize ) }</Col>

                            </Row>

                        </>

                }

            </>

        )

    }

    renderProgress() {

        const activeJunk = this.state.junks[ this.state.activeJunkIndex ]
        const progress   = ( ( 100 * this.state.activeJunkIndex + ( activeJunk ? activeJunk.progress : 0 ) ) / ( this.state.junks.length * 100 ) ) * 100

        return (

            <>

                <div className='lead'>

                    { activeJunk && <div className='mb-4'>

                        <Row className='mb-2'>

                            <Col>{ _( 'Teil' ) } { this.state.activeJunkIndex + 1 } / { this.state.junks.length }</Col>

                            <Col className='text-end'>{ getReadableFileSize( activeJunk.file.size * activeJunk.progress / 100 ) } { _( 'von' ) } { getReadableFileSize( activeJunk.file.size ) }</Col>

                        </Row>

                        <ProgressBar animated striped now={ activeJunk.progress < 5 ? 5 : activeJunk.progress } variant='secondary' />

                    </div> }

                    <div>

                        <Row className='fw-900 mb-2'>

                            <Col>{ _( 'Gesamt' ) }</Col>

                            <Col className='text-end'>{ getReadableFileSize( this.state.file.size * progress / 100 ) } { _( 'von' ) } { getReadableFileSize( this.state.file.size ) }</Col>

                        </Row>

                        <ProgressBar animated striped now={ progress < 2 ? 2 : progress } />

                    </div>

                </div>

                <Alert variant='warning' className='mt-5 mb-0'><Icon icon='exclamation-triangle' /> { _( 'Browser während dem Upload nicht schließen!' ) }</Alert>

            </>

        )

    }

    render() {

        const uploadUrl = this.getUploadUrl()

        return (

            <Modal show={ this.state.show } onHide={ () => this.props.onHide() } centered backdrop='static'>

                <Modal.Header>

                    <Modal.Title>{ _( 'Videodatei hochladen' ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    {

                        ! uploadUrl ?

                            <Alert variant='danger'>

                                <Alert.Heading><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</Alert.Heading>

                                <p>{ _( 'Keine Upload-URL gefunden.' ) }</p>

                            </Alert>

                        : ! this.props.uploadAuth ?

                            <Alert variant='danger'>

                                <Alert.Heading><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</Alert.Heading>

                                <p>{ _( 'Kein Upload-Token gefunden.' ) }</p>

                            </Alert>

                        : this.state.error ?

                            <Alert variant='danger'>

                                <Alert.Heading><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</Alert.Heading>

                                <p>{ _( 'Siehe Browser Console für Details.' ) }</p>

                            </Alert>

                        : this.state.success ?

                            <h3 className='text-center text-success'><Icon icon='check' /> { _( 'Upload erfolgreich' ) }</h3>

                        : this.state.isUploading ?

                            this.renderProgress()

                        :

                            this.renderForm()

                    }

                </Modal.Body>

                <Modal.Footer>

                    <Button onClick={ () => this.props.onHide() } disabled={ this.state.isUploading } variant='secondary'>{ _( 'Schließen' ) }</Button>

                    { ! this.state.isUploading && ! this.state.error && ! this.state.success && <Button onClick={ () => this.upload() } disabled={ ! this.state.file || ! this.state.title }>{ _( 'Upload starten' ) }</Button> }

                </Modal.Footer>

            </Modal>

        )

    }

}

export default LivestreamVideoFileUploadModal