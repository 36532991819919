import React from 'react'
import { Icon, translate as _, ajax, getDataUrl } from '@hockeydata/skynet'
import { Link, withRouter } from 'react-router-dom'
import { Button, Toast, ToastContainer } from 'react-bootstrap'
import VideoList from '../elements/VideoList'
import LivestreamVideoFileUploadModal from '../../containers/elements/LivestreamVideoFileUploadModal'

class Workspace extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            hasLoadingError:          false,
            videos:                   null,
            videoFileUploadModalOpen: false,
            workspace:                null,

        }

    }

    componentDidMount() {

        this.load()

    }

    addVideo( video ) {

        video && this.setState( { videos: [ ...( this.state.videos || [] ), {

            Id:           video.Id,
            Thumbnail:    null,
            Title:        video.title,
            Originalname: video.originalname,
            Description:  null,
            Path:         null,
            Filesystem:   null,
            Duration:     video.duration,
            CreatedAt:    null,
            UpdatedAt:    null,

        } ] } )

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleIsLoading( true )

            const itemsToLoad = [

                this.loadItems( { url: 'api/Workspace/Get',       data: { id: this.props.match.params.id }, success: ( e, resolve ) => this.setState( { workspace: e[ 0 ] }, resolve ) } ),
                this.loadItems( { url: 'api/Workspace/GetVideos', data: { workspaceId: this.props.match.params.id }, name: 'videos'                                                    } ),

            ]

            Promise
                .all( itemsToLoad )
                .catch( () => this.setState( { hasLoadingError: true } ) )
                .finally( () => this.props.onToggleIsLoading( false ) )

        }, 400 ) )

    }

    loadItems( options ) {

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), { token: this.props.token, ...options.data } )
                .then( e => e.StatusId > 0 && e.Data ? options.success ? options.success( e.Data, resolve ) : this.setState( { [ options.name ]: e.Data }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    toggleVideoFileUploadModal() {

        this.setState( { videoFileUploadModalOpen: ! this.state.videoFileUploadModalOpen } )

    }

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='layer-group' className='subheader-icon' /> { _( 'Workspace' ) } { this.state.workspace && <span className='fw-300'>{ this.state.workspace.Name }</span> }

                    </h1>

                    { this.state.workspace && this.state.workspace.UploadAllowed && <Button onClick={ () => this.toggleVideoFileUploadModal() } variant='primary' className='me-3'>{ _( 'Video hochladen' ) }</Button> }

                    <Link to='/workspaces' className='fs-3 ms-auto pe-2' title={ _( 'Schließen' ) }><Icon icon='times' /></Link>

                </div>

                <VideoList videos={ this.state.videos } channelUrl={ this.state.workspace && this.state.workspace.ChannelUrl } />

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

                <LivestreamVideoFileUploadModal workspaceUrlSlug={ this.state.workspace && this.state.workspace.Urlslug } channelUrl={ this.state.workspace && this.state.workspace.ChannelUrl } show={ this.state.videoFileUploadModalOpen } onHide={ () => this.toggleVideoFileUploadModal() } onUpload={ e => this.addVideo( e ) } />

            </>

        )

    }

}

export default withRouter( Workspace )