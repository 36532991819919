import React from 'react'
import { Icon, translate as _ } from '@hockeydata/skynet'
import { Badge, Card, Col, Row } from 'react-bootstrap'
import { getPaymentStatusLabel } from '../../util'
import { PAYMENT_STATUS_DONE, PAYMENT_SYSTEM_COUPON } from '../../util/constants'
import { formatDate, formatTime } from '../../util/date'

class Payment extends React.Component {

    render() {

        const isCoupon = this.props.item.PaymentSystem === PAYMENT_SYSTEM_COUPON

        return (

            <Card className='mb-2 payment-item'>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col xs='4' sm='3' md='2' xl='1' className='text-center py-1'>

                            <Badge>

                                <div>{ formatDate( this.props.item.CreatedAt, { weekday: 'short', year: 'none' } ) }</div>

                                <div>{ formatTime( this.props.item.CreatedAt ) }</div>

                            </Badge>

                        </Col>

                        <Col xs='8' sm='9' md='8' xl='4' className='py-1'>

                            { isCoupon ? <Badge bg='info'>{ _( 'COUPON' ) }</Badge> : <span className='lead'>{ this.props.item.Email }</span> }

                            { this.props.item.PaymentStatus === PAYMENT_STATUS_DONE && <a href={ this.props.item.PaymentLink } className='float-end'><Icon icon='download' size='2' /></a> }

                        </Col>

                        <Col xs='4' sm='3' md='2' xl='1' className='text-center py-1'>

                            { this.props.item.Amount !== null && this.props.item.Amount.toFixed ? <Badge bg='secondary'>{ this.props.item.Amount.toFixed( 2 ).replace( '.', ',' ) } €</Badge> : '' }

                        </Col>

                        <Col xs='8' sm='9' md={ { span: 8, offset: 2 } } xl={ { span: 5, offset: 0 } } className='py-1'>

                            { ! isCoupon && this.props.item.Title }

                        </Col>

                        <Col xs='4' sm='3' md='2' xl='1' className='text-center py-1'>

                            { getPaymentStatusLabel( this.props.item.PaymentStatus ) }

                        </Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

}

export default Payment