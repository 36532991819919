import React from 'react'
import { Form } from 'react-bootstrap'

class LabeledSwitch extends React.Component {

    render() {

        return (

            <span className='labeled-switch'>

                <div className={ 'labeled-switch-label' + ( this.props.disabled ? ' disabled' : '' ) }>{ this.props.label }</div>

                <Form.Switch inline checked={ this.props.checked } onChange={ e => this.props.onChange( e ) } disabled={ this.props.disabled } />

            </span>

        )

    }

}

export default LabeledSwitch