import { translate as _ } from '@hockeydata/skynet'
import { findTextInObject } from '../../util'
import Workspace from '../elements/Workspace'
import Page from '../templates/Page'

class Workspaces extends Page {

    constructor( props ) {

        super( props )

        this.itemComponent = Workspace
        this.pageIcon      = 'layer-group'
        this.pageTitle     = _( 'Workspaces' )

    }

    checkItem( item ) {

        return ! this.props.textFilter || findTextInObject( item, this.props.textFilter, [ 'Name' ] )

    }

    getDataUrl() {

        return 'api/Workspace/Get?token=' + this.props.token

    }

}

export default Workspaces