import { ajax, getDataUrl, translate as _ } from '@hockeydata/skynet'
import { Button, Col, Form, Row } from 'react-bootstrap'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import { findTextInObject, getDateRangePickerLocale, hasPrivilege, isSoon } from '../../util'
import { BROADCAST_STATUS_ENDED, LIVESTREAMS_STATUS_FILTER_ALL_GAMES, LIVESTREAMS_STATUS_FILTER_BROADCAST, LIVESTREAMS_STATUS_FILTER_DONT_BROADCAST, PRIV_REFRESH_GAMES } from '../../util/constants'
import { createDate } from '../../util/date'
import ReloadStatusButton from '../controls/ReloadStatusButton'
import Livestream from '../elements/Livestream'
import Page from '../templates/Page'

class Livestreams extends Page {

    #reloadStatusTimer

    constructor( props ) {

        super( props )

        this.dataSortKey          = 'StartTime'
        this.itemComponent        = Livestream
        this.enableInfiniteScroll = false // true TODO Enable
        this.pageIcon             = 'video'
        this.pageTitle            = _( 'Livestreams' )
        this.statusFilterOptions  = [

            { label: _( 'Broadcast'        ), value: LIVESTREAMS_STATUS_FILTER_BROADCAST      },
            { label: _( 'Don\'t Broadcast' ), value: LIVESTREAMS_STATUS_FILTER_DONT_BROADCAST },
            { label: _( 'Alle Spiele'      ), value: LIVESTREAMS_STATUS_FILTER_ALL_GAMES      },

        ]

    }

    checkItem( item ) {

        if ( this.props.textFilter && ! findTextInObject( item, this.props.textFilter, [ 'CameraDescription', 'CompetitionName', 'LocationName', 'Title' ] ) ) {

            return false

        }

        return true

    }

    getBroadcastableParameter() {

        const statusFilter = Number( this.props.statusFilter )

        if ( statusFilter === LIVESTREAMS_STATUS_FILTER_ALL_GAMES ) {

            return ''

        }

        return '&broadcastable=' + ( statusFilter === LIVESTREAMS_STATUS_FILTER_BROADCAST ? 'true' : 'false' )

    }

    getChannelParameter() {

        return ( this.props.channelFilter ? '&channelId=' + this.props.channelFilter : '' )

    }

    getDataUrl() {

        return 'api/Overview/GetGames?token=' + this.props.token + '&from=' + this.props.dateRangeFrom.toJSON() + '&to=' + this.props.dateRangeTo.toJSON() + this.getBroadcastableParameter() + this.getChannelParameter()

    }

    loadFilters() {

        ajax( getDataUrl( 'api/Coupon/GetChannels?token=' + this.props.token ) )
            .then( e => this.setState( { channels: e.Data || [] } ) )
            .finally( () => this.loadData() )

    }

    reloadStatus() {

        this.setState( { hasReloadingStatusError: false, isReloadingStatus: true }, () => {

            const err = () => this.setState( { hasReloadingStatusError: true }, () => setTimeout( () => this.setState( { hasReloadingStatusError: false } ), 5000 ) )

            const success = newData => {

                const data = [ ...( this.state.data || [] ) ]

                newData.forEach( newItem => {

                    const index = data.findIndex( item => item.Id === newItem.Id )

                    index !== -1 && data.splice( index, 1, { ...data[ index ], ...newItem } )

                } )

                this.setState( { data } )

            }

            this.state.data && this.state.data.length ?

                ajax( getDataUrl( 'api/Overview/RefreshGames' ), { token: this.props.token, streamIds: { __arr: true, values: this.state.data.filter( e => e.BroadcastStatus !== BROADCAST_STATUS_ENDED && isSoon( e ) ).map( e => e.Id ) } }, { method: 'POST' } )
                    .then( e => e.StatusId > 0 ? success( e.Data ) : err() )
                    .catch( err )
                    .finally( () => this.setState( { isReloadingStatus: false, lastStatusReload: createDate() } ) )

            :

                this.setState( { isReloadingStatus: false, lastStatusReload: createDate() } )

        } )

    }

    renderFilters() {

        const canRefresh = hasPrivilege( this.props.user, PRIV_REFRESH_GAMES )

        return (

            <div className='data-filter'>

                <Row className='align-items-center mb-3 px-3'>

                    <Col className='mb-1' xs='12' sm='6' xl='3'>

                        <DateRangePicker initialSettings={ {

                            endDate:   this.props.dateRangeTo,
                            locale:    getDateRangePickerLocale( { format: 'DD.MM.YY HH:mm' } ),
                            startDate: this.props.dateRangeFrom,
                            timePicker: true,
                            timePicker24Hour: true,

                        } } onApply={ ( e, f ) => this.handleDateRangeChange( e, f, true ) }>

                            <Form.Control />

                        </DateRangePicker>

                    </Col>

                    <Col className='mb-1' xs='12' sm='6' xl='3'>

                        <Form.Select onChange={ e => this.props.onStatusFilterChange( e.target.value ) } value={ this.props.statusFilter }>

                            { this.statusFilterOptions && this.statusFilterOptions.map( ( e, i ) => <option value={ e.value } key={ i }>{ e.label }</option> ) }

                        </Form.Select>

                    </Col>

                    <Col className='mb-1' xs='12' sm='6' xl='3'>

                        <Form.Select onChange={ e => this.props.onChannelFilterChange( e.target.value ) } value={ this.props.channelFilter || '' }>

                            {

                                this.state.channels &&

                                <>

                                    <option value=''>{ _( 'Alle Kanäle' ) }</option>

                                    { this.state.channels.map( ( e, i ) => <option key={ i } value={ e.Id }>{ e.Name }</option> ) }

                                </>

                            }

                        </Form.Select>

                    </Col>

                    <Col className='mb-1' xs={ canRefresh ? 9 : 12 } sm={ canRefresh ? 4 : 6 } xl={ canRefresh ? 2 : 3 }>

                        <div className='d-grid'>

                            <Button onClick={ () => this.loadData() }>{ _( 'Laden' ) }</Button>

                        </div>

                    </Col>

                    { canRefresh && <Col className='mb-1' xs='3' sm='2' xl='1'>

                        <div className='d-grid'>

                                <ReloadStatusButton
                                    hasError={ this.state.hasReloadingStatusError }
                                    onClick={ () => this.reloadStatus() }
                                    disabled={ ! this.state.data || ! this.state.data.length || this.state.isReloadingStatus || this.props.isLoading }
                                    isLoading={ this.state.isReloadingStatus }
                                    lastUpdate={ this.state.lastStatusReload }
                                 ></ReloadStatusButton>

                        </div>

                    </Col> }

                </Row>

                <Row className='align-items-center mb-3 px-3'>

                    <Col className='mb-1'>

                        <Form.Control onChange={ e => this.props.onTextFilterChange( e.target.value ) } value={ this.props.textFilter } placeholder={ _( 'Suchergebnis filtern' ) } />

                    </Col>

                </Row>

            </div>

        )

    }

}

export default Livestreams