import { connect } from 'react-redux'
import CreateCouponCode from '../../components/pages/CreateCouponCode'

const mapStateToProps = state => {

    return {

        token: state.auth.token,

    }

}

export default connect( mapStateToProps )( CreateCouponCode )