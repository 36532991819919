import { connect } from 'react-redux'
import { storeActions } from '@hockeydata/skynet'
import Workspaces from '../../components/pages/Workspaces'
import { setUiValue } from '../../store/actions'

const mapStateToProps = state => {

    return {

        isLoading:  state.app.isLoading,
        textFilter: state.ui.textFilterWorkspaces || '',
        token:      state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onTextFilterChange: textFilter => dispatch( setUiValue( 'textFilterWorkspaces', textFilter   ) ),
        onToggleIsLoading:  isLoading  => dispatch( storeActions.app.toggleIsLoading( isLoading ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Workspaces )