import { connect } from 'react-redux'
import { storeActions } from '@hockeydata/skynet'
import Livestreams from '../../components/pages/Livestreams'
import { createDate } from '../../util/date'
import { setDateRange, setUiValue } from '../../store/actions'

const mapStateToProps = state => {

    return {

        channelFilter: state.ui.channelFilterLivestreams,
        dateRangeFrom: createDate( state.ui.dateRangeFromLivestreams ),
        dateRangeTo:   createDate( state.ui.dateRangeToLivestreams   ),
        isLoading:     state.app.isLoading,
        statusFilter:  state.ui.statusFilterLivestreams,
        textFilter:    state.ui.textFilterLivestreams,
        token:         state.auth.token,
        user:          state.auth.user,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onChannelFilterChange: statusFilter => dispatch( setUiValue( 'channelFilterLivestreams', statusFilter                         ) ),
        onDateRangeChange:     ( from, to ) => dispatch( setDateRange( from, to, 'dateRangeFromLivestreams', 'dateRangeToLivestreams' ) ),
        onStatusFilterChange:  statusFilter => dispatch( setUiValue( 'statusFilterLivestreams', statusFilter                          ) ),
        onTextFilterChange:    textFilter   => dispatch( setUiValue( 'textFilterLivestreams', textFilter                              ) ),
        onToggleIsLoading:     isLoading    => dispatch( storeActions.app.toggleIsLoading( isLoading                                  ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Livestreams )