import React from 'react'
import { ajax, getDataUrl, translate as _ } from '@hockeydata/skynet'
import { Button, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap'
import { getLivestreamTitle } from '../../util'

class LivestreamPriceModal extends React.Component {

    #dom

    constructor( props ) {

        super( props )

        this.state = {

            isSaving: false,
            price:    '',
            show:     false,

        }

        this.#dom = {

            price: React.createRef(),

        }

    }

    componentDidUpdate() {

        this.props.show !== this.state.show && this.setState( {

            price: this.props.livestream.CostInEur || '',
            show:  this.props.show,

        } )

    }

    closeModal() {

        this.setState( { isSaving: false }, () => this.props.onHide() )

        return true

    }

    save() {

        const price = Number( this.state.price.replace( ',', '.' ) )

        if ( isNaN( price ) ) {

            return this.#dom.price.current.focus()

        }

        this.setState( { isSaving: true }, () =>

            ajax( getDataUrl( 'api/Broadcast/SetPrice' ), { streamId: this.props.livestream.Id, price: price, token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && this.setState( { price }, () => this.closeModal() && this.props.onChange( price ) ) )
                .finally( () => this.setState( { isSaving: false } ) )

        )

    }

    render() {

        return (

            <Modal show={ this.state.show } onHide={ () => this.closeModal() } centered backdrop='static'>

                <Modal.Header closeButton>

                    <Modal.Title>{ getLivestreamTitle( this.props.livestream ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    <fieldset disabled={ this.state.isSaving }>

                        <Form.Group as={ Row } className='mb-3' controlId='price'>

                            <Form.Label column sm='4'>{ _( 'Preis' ) }</Form.Label>

                            <Col sm='8'>

                                <InputGroup>

                                    <Form.Control type='number' min='0' step='.01' value={ this.state.price } onChange={ e => this.setState( { price: e.target.value } ) } ref={ this.#dom.price } />

                                    <InputGroup.Text>€</InputGroup.Text>

                                </InputGroup>

                            </Col>

                        </Form.Group>

                    </fieldset>

                </Modal.Body>

                <Modal.Footer>

                    <Button onClick={ () => this.closeModal() } disabled={ this.state.isSaving } variant='secondary'>{ _( 'Schließen' ) }</Button>
                    <Button onClick={ () => this.save() } disabled={ this.state.isSaving }>{ _( 'Speichern' ) }</Button>

                </Modal.Footer>

            </Modal>

        )

    }

}

export default LivestreamPriceModal