import { connect } from 'react-redux'
import { storeActions } from '@hockeydata/skynet'
import Suspensions from '../../components/pages/Suspensions'
import { setUiValue } from '../../store/actions'

const mapStateToProps = state => {

    return {

        isLoading:  state.app.isLoading,
        textFilter: state.ui.textFilterSuspensions,
        token:      state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onTextFilterChange: textFilter => dispatch( setUiValue( 'textFilterSuspensions', textFilter ) ),
        onToggleIsLoading:  isLoading  => dispatch( storeActions.app.toggleIsLoading( isLoading     ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Suspensions )