import React from 'react'
import { reloadPage } from '../util'
import { translate as _ } from '@hockeydata/skynet'
import { Button } from 'react-bootstrap'

class ErrorBoundary extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            detailsOpen: false,
            error:       null,
            hasError:    false,

        }

    }

    componentDidCatch( error, info ) {

        console.error( 'components.ErrorBoundary.componentDidCatch', error, info )

    }

    static getDerivedStateFromError( error ) {

        console.error( 'components.ErrorBoundary.getDerivedStateFromError', error )

        return {

            error,
            hasError: true,

        }

    }

    resetApp() {

        localStorage.clear()

        reloadPage()

    }

    showDetails() {

        this.setState( { detailsOpen: true } )

    }

    render() {

        if ( this.state.hasError ) {

            return (

                <div className='error-boundary'>

                    <h2 className='mb-5'>{ _( 'Ups... da ist etwas schiefgelaufen :-(' ) }</h2>

                    <div className='d-grid mb-2'><Button onClick={ reloadPage }>{ _( 'App neuladen' ) }</Button></div>

                    <div className='d-grid mb-2'><Button onClick={ () => this.resetApp() }>{ _( 'App zurücksetzen' ) }</Button></div>

                    <div className='d-grid mb-2'><Button onClick={ () => this.showDetails() } variant='secondary'>{ _( 'Details anzeigen' ) }</Button></div>

                    { this.state.detailsOpen &&

                        <div className='mt-5'>

                            <p className='fw-700'>{ _( 'Details' ) }:</p>

                            {

                                this.state.error &&

                                <div>

                                    <code>

                                        { this.state.error.stack }

                                    </code>

                                </div>

                            }

                        </div>

                    }

                </div>

            )

        }

        return this.props.children

    }
}

export default ErrorBoundary