import { connect } from 'react-redux'
import LivestreamVideoFileUploadModal from '../../components/elements/LivestreamVideoFileUploadModal'

const mapStateToProps = ( state, props ) => {

    const videoUploadToken = state.auth && state.auth.user && state.auth.user.VideoUploadTokens && props.channelUrl && state.auth.user.VideoUploadTokens.find( e => e.Name === props.channelUrl )

    return {

        uploadAuth: videoUploadToken && videoUploadToken.Token,

    }

}

export default connect( mapStateToProps )( LivestreamVideoFileUploadModal )