import React from 'react'
import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import { Alert, Button, Col, Form, Row } from 'react-bootstrap'
import { createDate, fillDate } from '../../util/date'
import { getAbsoluteHeight, getInnerHeight, getStyleProperty } from '../../util/dom'

class Page extends React.Component {

    dataSortKey
    enableInfiniteScroll
    itemComponent
    pageIcon
    pageTitle
    statusFilterOptions

    #dataLimit
    #dom

    constructor( props ) {

        super( props )

        this.state = {

            channels:                null,
            data:                    null,
            dataContainerHeight:     null,
            dataOffset:              0,
            hasLoadedAll:            false,
            hasLoadingError:         false,
            hasReloadingStatusError: false,
            isReloadingStatus:       false,
            lastStatusReload:        createDate(),

        }

        this.handleDataContainerScroll = this.handleDataContainerScroll.bind( this )
        this.handleWindowResize        = this.handleWindowResize.bind( this )

        this.#dataLimit = 20
        this.#dom       = {

            dataContainer: React.createRef(),

        }

    }

    componentDidMount() {

        this.calculateDataContainerHeight()
        this.loadFilters()

        window.addEventListener( 'resize', this.handleWindowResize )

        this.enableInfiniteScroll && this.#dom.dataContainer.current.addEventListener( 'scroll', this.handleDataContainerScroll )

    }

    componentWillUnmount() {

        window.removeEventListener( 'resize', this.handleWindowResize )

        this.enableInfiniteScroll && this.#dom.dataContainer.current.removeEventListener( 'scroll', this.handleDataContainerScroll )

    }

    addItem( item ) {

        const data = [ ...this.state.data ]

        data.push( item )

        this.sortData( data )

        this.setState( { data } )

    }

    calculateDataContainerHeight() {

        let dataContainerHeight = 0

        if ( window.innerHeight > 600 ) {

            const pageHeaderHeight         = getAbsoluteHeight( '.page-header' )
            const pageFooterHeight         = getAbsoluteHeight( '.page-footer' )
            const pageContentPaddingTop    = Number( getStyleProperty( '.page-content', 'padding-top' ).replace( /\D+/g, '' ) )
            const pageContentPaddingBottom = Number( getStyleProperty( '.page-content', 'padding-bottom' ).replace( /\D+/g, '' ) )
            const subHeaderHeight          = getAbsoluteHeight( '.subheader' )
            const dataFilterHeight         = getAbsoluteHeight( '.data-filter' )

            dataContainerHeight = window.innerHeight
                                    - pageHeaderHeight
                                    - pageFooterHeight
                                    - pageContentPaddingTop
                                    - pageContentPaddingBottom
                                    - subHeaderHeight
                                    - dataFilterHeight

        }

        this.setState( { dataContainerHeight } )

    }

    checkItem() {

        return true

    }

    getDataRequestOptions() {}

    getDataRequestParameters() {}

    getDataUrl() {}

    handleDataContainerScroll( e ) {

        const innerHeight  = getInnerHeight( this.#dom.dataContainer.current )
        const scrollHeight = this.#dom.dataContainer.current.scrollHeight
        const scrollTop    = this.#dom.dataContainer.current.scrollTop

        if ( this.props.isLoading || this.state.hasLoadedAll || innerHeight + scrollTop < scrollHeight ) {

            return

        }

        this.loadMore()

    }

    handleDateRangeChange( e, f, timePicker ) {

        this.props.onDateRangeChange(

            f.startDate ? f.startDate.toDate().toJSON() : null,
            f.endDate   ? ( timePicker ? f.endDate.toDate().toJSON() : fillDate( f.endDate.toDate() ).toJSON() ) : null

        )

    }

    handleWindowResize() {

        this.calculateDataContainerHeight()

    }

    load() {}

    loadData() {

        this.setState( { dataOffset: 0, hasLoadedAll: false }, () => this.loadMore() )

    }

    loadFilters() {

        this.loadData()

    }

    loadMore() {

        this.setState( { hasLoadingError: false }, () => {

            this.props.onToggleIsLoading( true )

            let dataUrl = getDataUrl( this.getDataUrl() )

            if ( this.enableInfiniteScroll ) {

                dataUrl += '&limit=' + this.#dataLimit + '&offset=' + this.state.dataOffset

            }

            const success = e => {

                this.setData( e )

                this.enableInfiniteScroll && ( e.length < this.#dataLimit ? this.setState( { hasLoadedAll: true } ) : this.setState( { dataOffset: this.state.dataOffset + this.#dataLimit } ) )

            }

            ajax( dataUrl, this.getDataRequestParameters(), this.getDataRequestOptions() )
                .then( e => e.StatusId > 0 && e.Data ? success( e.Data ) : this.setState( { hasLoadingError: true } ) )
                .catch( () => this.setState( { hasLoadingError: true } ) )
                .finally( () => this.setState( { lastStatusReload: createDate() }, () => this.props.onToggleIsLoading( false ) ) )

        } )

    }

    changeItem( item ) {

        const index = this.state.data.findIndex( e => e.Id === item.Id )
        const data  = [ ...this.state.data ]

        data[ index ] = item

        this.setState( { data } )

    }

    removeItem( item ) {

        const index = this.state.data.findIndex( e => e.Id === item.Id )
        const data  = [ ...this.state.data ]

        data.splice( index, 1 )

        this.setState( { data } )

    }

    setData( newData ) {

        let data = [ ...( this.state.data || [] ) ]

        newData.forEach( newItem => {

            const index = data.findIndex( e => e.Id === newItem.Id )

            if ( index === -1 ) {

                data.push( newItem )

            } else {

                data[ index ] = newItem

            }

        } )

        data = data.filter( item => newData.find( e => e.Id === item.Id ) )

        this.dataSortKey && data.sort( ( a, b ) => createDate( a[ this.dataSortKey ] ).getTime() - createDate( b[ this.dataSortKey ] ).getTime() )

        this.setState( { data }, () => this.calculateDataContainerHeight() )

    }

    sortData() {}

    renderFilters() {

        return (

            <div className='data-filter'>

                <Row className='align-items-center mb-3 px-3'>

                    <Col className='mb-1' xs='12' sm='6' md='3' xl='2'>

                        <div className='d-grid'>

                            <Button onClick={ () => this.loadData() }>{ _( 'Laden' ) }</Button>

                        </div>

                    </Col>

                    <Col className='mb-1' xs='12' sm='6' md='9' xl='10'>

                        <Form.Control onChange={ e => this.props.onTextFilterChange( e.target.value ) } value={ this.props.textFilter } placeholder={ _( 'Suchergebnis filtern' ) } />

                    </Col>

                </Row>

            </div>

        )

    }

    renderFooter() {}

    renderItem( item, i ) {

        const ItemComponent = this.itemComponent

        return (

            <ItemComponent
                key={ i }
                item={ item }
                onChange={ item => this.changeItem( item ) }
                onRemove={ item => this.removeItem( item ) }
                token={ this.props.token }
                user={ this.props.user }
            />

        )

    }

    renderItemCount( e ) {

        return (

            <div className='text-center my-3'>{ e } { e === 1 ? _( 'Eintrag' ) : _( 'Einträge' ) }</div>

        )

    }

    renderSubheaderBlock( filteredItems ) {

        return filteredItems !== null && this.renderItemCount( filteredItems.length )

    }

    render() {

        const filteredItems = this.state.data ? this.state.data.filter( e => this.checkItem( e ) ) : null

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon={ this.pageIcon } className='subheader-icon' /> { this.pageTitle }

                    </h1>

                    <div className='subheader-block'>

                        { this.renderSubheaderBlock( filteredItems ) }

                    </div>

                </div>

                { this.renderFilters() }

                {

                    this.state.hasLoadingError ?

                        <Alert variant='warning'>{ _( 'Daten konnten nicht geladen werden.' ) }</Alert>

                    :

                        <div className={ 'data-container' + ( this.props.isLoading ? ' loading' : '' ) } style={ { height: this.state.dataContainerHeight ? this.state.dataContainerHeight + 'px' : 'unset' } } ref={ this.#dom.dataContainer } onScroll={ this.enableInfiniteScroll ? e => this.handleDataContainerScroll( e ) : null }>

                            { filteredItems && filteredItems.map( ( e, i ) => this.renderItem( e, i ) ) }

                            { this.props.isLoading && <div className='data-overlay'><div><Icon icon='spinner' spin /></div></div> }

                            { this.enableInfiniteScroll && this.state.hasLoadedAll && <div className='my-5 text-center'><small>{ _( 'Alle Einträge geladen.' ) }</small></div> }

                        </div>

                }

                { this.renderFooter() }

            </>

        )

    }

}

export default Page