import React from 'react'
import { Icon, translate as _, ajax, getDataUrl } from '@hockeydata/skynet'
import { Link, withRouter } from 'react-router-dom'
import Select from 'react-select'
import { Button, Card, Col, Form, Modal, OverlayTrigger, Row, Toast, ToastContainer, Tooltip } from 'react-bootstrap'
import { ENTITY_TYPE_COMPETITION, ENTITY_TYPE_TEAM } from '../../util/constants'

class User extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            addMappingModalOpen: false,
            addRoleModalOpen:    false,
            competitions:        null,
            hasLoadingError:     false,
            isAddingMapping:     false,
            isAddingRole:        false,
            mappingType:         '',
            mappingTypes:        null,
            mappingValue:        null,
            role:                '',
            roles:               [],
            teams:               null,
            user:                null,

        }

    }

    componentDidMount() {

        this.load()

    }

    addMapping() {

        this.setState( { isAddingMapping: true }, () => {

            const mappingType = this.state.mappingTypes && this.state.mappingTypes.find( e => e.Id === Number( this.state.mappingType ) )
            const data        = {

                accountId:    this.props.match.params.id,
                abroadDB:     mappingType.DatabaseContext,
                abroadEntity: mappingType.Entity,
                abroadColumn: mappingType.Value,
                abroadValue:  this.state.mappingValue && this.state.mappingValue.value,
                token:        this.props.token,

            }

            ajax( getDataUrl( 'api/EntityMapping/AddMapping' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && this.setState( { addMappingModalOpen: false, user: { ...this.state.user, EntityMappings: [ ...this.state.user.EntityMappings, e.Data[ 0 ] ] } } ) )
                .finally( () => this.setState( { isAddingMapping: false } ) )

        } )

    }

    addRole() {

        this.setState( { isAddingRole: true }, () => {

            const roleId = Number( this.state.role )

            ajax( getDataUrl( 'api/Role/AddUsers' ), { userIds: Number( this.props.match.params.id ), token: this.props.token, roleId }, { method: 'POST' } )
                .then( e => e.StatusId > 0 && this.setState( { addRoleModalOpen: false, user: { ...this.state.user, RoleMaps: [ ...this.state.user.RoleMaps, { Role: this.state.roles.find( e => e.Id === roleId ) } ] } } ) )
                .finally( () => this.setState( { isAddingRole: false } ) )

        } )

    }

    dismissLoadingError() {

        this.setState( { hasLoadingError: false } )

    }

    getEntityName( entity ) {

        switch ( entity.AbroadEntity ) {

            case ENTITY_TYPE_COMPETITION: {

                const competition = this.state.competitions && this.state.competitions.find( e => e.Id === Number( entity.AbroadValue ) )

                return ( competition ? competition.Name : '' ) + ' (' + entity.AbroadValue + ')'

            }

            case ENTITY_TYPE_TEAM: {

                const team = this.state.teams && this.state.teams.find( e => e.Id === Number( entity.AbroadValue ) )

                return ( team ? team.Name : '' ) + ' (' + entity.AbroadValue + ')'

            }

            default: {

                return '[Invalid AbroadEntity]'

            }

        }

    }

    hideAddRoleModal() {

        this.setState( { addRoleModalOpen: false } )

    }

    hideAddMappingModal() {

        this.setState( { addMappingModalOpen: false } )

    }

    load() {

        this.setState( { hasLoadingError: false }, () => setTimeout( () => {

            this.props.onToggleIsLoading( true )

            const itemsToLoad = [

                this.loadItems( { url: 'api/Account/Get',       data: { id: this.props.match.params.id }, success: ( e, resolve ) => this.setState( { user: e[ 0 ] }, resolve ) } ),
                this.loadItems( { url: 'api/Role/Get',          method: 'POST', name: 'roles'                                                                                   } ),
                this.loadItems( { url: 'api/Competition/Get',   name: 'competitions'                                                                                            } ),
                this.loadItems( { url: 'api/Team/Get',          name: 'teams'                                                                                                   } ),
                this.loadItems( { url: 'api/EntityMapping/Get', method: 'POST', name: 'mappingTypes'                                                                            } ),

            ]

            Promise
                .all( itemsToLoad )
                .catch( () => this.setState( { hasLoadingError: true } ) )
                .finally( () => this.props.onToggleIsLoading( false ) )

        }, 400 ) )

    }

    loadItems( options ) {

        let data = { token: this.props.token }

        if ( options.data ) {

            data = { ...data, ...options.data }

        }

        return new Promise( ( resolve, reject ) => {

            ajax( getDataUrl( options.url ), data, { method: options.method || 'GET' } )
                .then( e => e.StatusId > 0 && e.Data ? options.success ? options.success( e.Data, resolve ) : this.setState( { [ options.name ]: e.Data }, resolve ) : reject() )
                .catch( reject )

        } )

    }

    removeMapping( mapping ) {

        ajax( getDataUrl( 'api/EntityMapping/Delete' ), { id: Number( mapping.Id ), token: this.props.token }, { method: 'POST' } )
            .then( e => e.StatusId > 0 && this.setState( { user: { ...this.state.user, EntityMappings: this.state.user.EntityMappings.filter( e => e.Id !== mapping.Id ) } } ) )

    }

    removeRole( role ) {

        ajax( getDataUrl( 'api/Role/DeleteUsers' ), { userIds: Number( this.props.match.params.id ), token: this.props.token, roleId: role.Role.Id }, { method: 'POST' } )
            .then( e => e.StatusId > 0 && this.setState( { user: { ...this.state.user, RoleMaps: this.state.user.RoleMaps.filter( e => e.Role.Id !== role.Role.Id ) } } ) )

    }

    renderMappings() {

        return (

            this.state.user && this.state.user.EntityMappings.map( ( e, i ) =>

                <Row className='align-items-center' key={ i }>

                    <Col>{ this.getEntityName( e ) }</Col>

                    <Col xs='auto'>

                        <Button variant='outline-secondary' className='border-0' size='sm' onClick={ () => this.removeMapping( e ) }><Icon icon='times' /></Button>

                    </Col>

                </Row>

            )

        )

    }

    renderMappingValueRow() {

        const mappingType = this.state.mappingTypes && this.state.mappingTypes.find( e => e.Id === Number( this.state.mappingType ) )

        switch ( mappingType && mappingType.Entity ) {

            case ENTITY_TYPE_COMPETITION: {

                return (

                    <Form.Group as={ Row } controlId='mappingValue' className='mt-3'>

                        <Form.Label column sm='4'>{ _( 'Competition' ) }</Form.Label>

                        <Col sm='8'>

                            <Select
                                onChange={ mappingValue => this.setState( { mappingValue } ) }
                                options={ this.state.competitions && this.state.competitions.map( e => ( { value: e.Id, label: e.Name + ' (' + e.Id + ')' } ) ) }
                                placeholder=''
                                value={ this.props.mappingValue }
                            />

                        </Col>

                    </Form.Group>

                )

            }

            case ENTITY_TYPE_TEAM: {

                return (

                    <Form.Group as={ Row } controlId='mappingValue' className='mt-3'>

                        <Form.Label column sm='4'>{ _( 'Team' ) }</Form.Label>

                        <Col sm='8'>

                            <Select
                                onChange={ mappingValue => this.setState( { mappingValue } ) }
                                options={ this.state.teams && this.state.teams.map( e => ( { value: e.Id, label: e.Name + ' (' + e.Id + ')' } ) ) }
                                placeholder=''
                                value={ this.props.mappingValue }
                            />

                        </Col>

                    </Form.Group>

                )

            }

            default: {

                return null

            }

        }

    }

    renderRoles() {

        return (

            this.state.user && this.state.user.RoleMaps.map( ( e, i ) =>

                <Row className='align-items-center' key={ i }>

                    <Col>{ e.Role.Name }</Col>

                    <Col xs='auto'>

                        { this.state.user.RoleMaps > 1 && <Button variant='outline-secondary' className='border-0' size='sm' onClick={ () => this.removeRole( e ) }><Icon icon='times' /></Button> }

                    </Col>

                </Row>

            )

        )

    }

    render() {

        const formDisabled    = this.props.loading || this.state.hasLoadingError
        const unassignedRoles = []

        this.state.roles && this.state.user && this.state.roles.forEach( role => ! this.state.user.RoleMaps.find( e => e.Role.Id === role.Id ) && unassignedRoles.push( role ) )

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='user' className='subheader-icon' /> { _( 'Benutzer' ) } { this.state.user && <span className='fw-300'>{ this.state.user.Mail }</span> }

                    </h1>

                    <Link to='/benutzer' className='fs-3 ms-auto pe-2' title={ _( 'Schließen' ) }><Icon icon='times' /></Link>

                </div>

                <Row>

                    <Col>

                        <Card>

                            <Card.Header>

                                <Row className='align-items-center'>

                                    <Col>

                                        <h4 className='my-0'>{ _( 'Rollen' ) }</h4>

                                    </Col>

                                    <Col xs='auto'>

                                        <OverlayTrigger overlay={ <Tooltip>{ unassignedRoles.length ? _( 'Rolle hinzufügen' ) : _( 'Keine weiteren Rollen verfügbar' ) }</Tooltip> }>

                                            <span>

                                                <Button variant='outline-secondary' className='border-0' size='sm' onClick={ () => this.setState( { addRoleModalOpen: true, role: '' } ) } disabled={ formDisabled || ! unassignedRoles.length }><Icon icon='plus' /></Button>

                                            </span>

                                        </OverlayTrigger>

                                    </Col>

                                </Row>

                            </Card.Header>

                            <Card.Body>

                                { this.renderRoles() }

                            </Card.Body>

                        </Card>

                    </Col>

                    <Col>

                        <Card>

                            <Card.Header>

                                <Row className='align-items-center'>

                                    <Col>

                                        <h4 className='my-0'>{ _( 'Zuweisungen' ) }</h4>

                                    </Col>

                                    <Col xs='auto'>

                                        <OverlayTrigger overlay={ <Tooltip>{ _( 'Zuweisung hinzufügen' ) }</Tooltip> }>

                                            <span>

                                                <Button variant='outline-secondary' className='border-0' size='sm' onClick={ () => this.setState( { addMappingModalOpen: true, mappingType: null, mappingValue: null } ) } disabled={ formDisabled }><Icon icon='plus' /></Button>

                                            </span>

                                        </OverlayTrigger>

                                    </Col>

                                </Row>

                            </Card.Header>

                            <Card.Body>

                                { this.renderMappings() }

                            </Card.Body>

                        </Card>

                    </Col>

                </Row>

                <Modal show={ this.state.addRoleModalOpen } onHide={ () => this.hideAddRoleModal() } backdrop='static' centered size='xs'>

                    <Modal.Header>

                        <Modal.Title>{ _( 'Rolle hinzufügen' ) }</Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        <Form.Group as={ Row } controlId='role'>

                            <Form.Label column sm='4'>{ _( 'Rolle' ) }</Form.Label>

                            <Col sm='8'>

                                <Form.Select value={ this.state.role } onChange={ e => this.setState( { role: e.target.value } ) } disabled={ this.state.isAddingRole }>

                                    <option></option>

                                    { unassignedRoles.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> ) }

                                </Form.Select>

                            </Col>

                        </Form.Group>

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.hideAddRoleModal() } disabled={ this.state.isAddingRole } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                        <Button onClick={ () => this.addRole() } disabled={ this.state.isAddingRole || ! this.state.role }>{ _( 'Hinzufügen' ) }</Button>

                    </Modal.Footer>

                </Modal>

                <Modal show={ this.state.addMappingModalOpen } onHide={ () => this.hideAddMappingModal() } backdrop='static' centered>

                    <Modal.Header>

                        <Modal.Title>{ _( 'Zuweisung hinzufügen' ) }</Modal.Title>

                    </Modal.Header>

                    <Modal.Body>

                        <Form.Group as={ Row } controlId='mappingType'>

                            <Form.Label column sm='4'>{ _( 'Type' ) }</Form.Label>

                            <Col sm='8'>

                                <Form.Select value={ this.state.mappingType } onChange={ e => this.setState( { mappingType: e.target.value, mappingValue: null } ) } disabled={ this.state.isAddingMapping }>

                                    <option></option>

                                    { this.state.mappingTypes && this.state.mappingTypes.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Entity }</option> ) }

                                </Form.Select>

                            </Col>

                        </Form.Group>

                        { this.renderMappingValueRow() }

                    </Modal.Body>

                    <Modal.Footer>

                        <Button onClick={ () => this.hideAddMappingModal() } disabled={ this.state.isAddingMapping } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                        <Button onClick={ () => this.addMapping() } disabled={ this.state.isAddingMapping || ! this.state.mappingType || ! this.state.mappingValue }>{ _( 'Hinzufügen' ) }</Button>

                    </Modal.Footer>

                </Modal>

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast onClose={ () => this.dismissLoadingError() } show={ this.state.hasLoadingError }>

                        <Toast.Header>

                            <div className='flex-grow-1'><Icon icon='exclamation-triangle' /> { _( 'Fehler' ) }</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>{ _( 'Daten konnten nicht geladen werden.' ) }</p>

                            <Button onClick={ () => this.load() } variant='secondary'>{ _( 'Erneut versuchen' ) }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </>

        )

    }

}

export default withRouter( User )