import React from 'react'
import { ajax, getDataUrl, Icon, translate as _ } from '@hockeydata/skynet'
import { createDate, formatDateInput } from '../../util/date'
import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap'
import CtCInput from '../elements/CtCInput'

class CreateCouponCode extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            channels:            null,
            coupon:              this.getDefaultCoupon(),
            isCreatingCoupon:    false,
            subscriptionOptions: null,

        }

    }

    componentDidMount() {

        this.loadChannels()

    }

    changeCoupon( e ) {

        this.setState( { coupon: { ...this.state.coupon, [ e.target.name ]: e.target.value } }, () => e.target.name === 'channel' && this.loadSubscriptionOptions() )

    }

    createCoupon() {

        this.setState( { isCreatingCoupon: true }, () => {

            const data = {

                accessibleId:         0,
                accessibleType:       '',
                channelId:            Number( this.state.coupon.channel ),
                subscriptionDays:     0,
                subscriptionOptionId: Number( this.state.coupon.subscriptionOption ),
                subscriptionUntil:    '',
                token:                this.props.token,

            }

            if ( this.state.coupon.validity === 'date' ) {

                data.subscriptionUntil = formatDateInput( createDate( this.state.coupon.validUntil ) )

            } else if ( this.state.coupon.validity === 'duration' ) {

                data.subscriptionDays = Number( this.state.coupon.duration )

            } else {

                data.accessibleId   = Number( this.state.coupon.accessibleId )
                data.accessibleType = 'Live'

            }

            ajax( getDataUrl( 'api/Coupon/CreateCoupon' ), data, { method: 'POST' } )
                .then( e => e.StatusId > 0 && this.setState( { coupon: { ...this.state.coupon, code: e.Data[ 0 ].Value } } ) )
                .finally( () => this.setState( { isCreatingCoupon: false } ) )

        } )

    }

    getDefaultCoupon() {

        return {

            accessibleId:       '',
            channel:            null,
            code:               null,
            duration:           '1',
            subscriptionOption: null,
            validity:           'date',
            validUntil:         '',

        }

    }

    loadChannels() {

        ajax( getDataUrl( 'api/Coupon/GetChannels?token=' + this.props.token ) ).then( e => e.StatusId > 0 && this.setState( { channels: e.Data, coupon: { ...this.state.coupon, channel: e.Data && e.Data.length ? e.Data[ 0 ].Id : null } }, () => this.loadSubscriptionOptions() ) )

    }

    loadSubscriptionOptions() {

        this.setState( { coupon: { ...this.state.coupon, subscriptionOption: null }, subscriptionOptions: null }, () => ajax( getDataUrl( 'api/Coupon/GetSubscriptionOptions?token=' + this.props.token + '&channelId=' + this.state.coupon.channel ) ).then( e => e.StatusId > 0 && this.setState( { subscriptionOptions: e.Data } ) ) )

    }

    reset() {

        this.setState( {

            coupon:           this.getDefaultCoupon(),
            couponCodeCopied: false,
            isCreatingCoupon: false,

        } )

    }

    render() {

        return (

            <>

                <div className='subheader'>

                    <h1 className='subheader-title'>

                        <Icon icon='ticket' className='subheader-icon' /> { _( 'Gutscheincode erstellen' ) }

                    </h1>

                </div>

                <Card>

                    <Card.Body>

                        <fieldset disabled={ this.state.isCreatingCoupon }>

                            <Form.Group as={ Row } className='mb-3' controlId='channel'>

                                <Form.Label column sm='4'>{ _( 'Channel' ) }</Form.Label>

                                <Col sm='8'>

                                    <Form.Select name='channel' value={ this.state.coupon.channel || '' } onChange={ e => this.changeCoupon( e ) } disabled={ this.state.channels === null }>

                                        {

                                            this.state.channels ?

                                                this.state.channels.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> )

                                            :

                                                <option>{ _( 'Daten werden geladen...' ) }</option>

                                        }

                                    </Form.Select>

                                </Col>

                            </Form.Group>

                            <Form.Group as={ Row } className='mb-3' controlId='subscriptionOption'>

                                <Form.Label column sm='4'>{ _( 'Subscription Option' ) }</Form.Label>

                                <Col sm='8'>

                                    <Form.Select name='subscriptionOption' value={ this.state.coupon.subscriptionOption || '' } onChange={ e => this.changeCoupon( e ) } disabled={ this.state.subscriptionOptions === null }>

                                        {

                                            this.state.subscriptionOptions ?

                                                <>

                                                    <option>{ _( '[kein]' ) }</option>

                                                    { this.state.subscriptionOptions.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> ) }

                                                </>

                                            :

                                                <option>{ _( 'Daten werden geladen...' ) }</option>

                                        }

                                    </Form.Select>

                                </Col>

                            </Form.Group>

                            <Form.Group as={ Row } className='mb-3' controlId='validUntil'>

                                <Form.Label column sm='4'>{ _( 'Gültig bis' ) }</Form.Label>

                                <Col sm='8'>

                                    <InputGroup>

                                        <InputGroup.Radio name='validity' value='date' onChange={ e => this.changeCoupon( e ) } checked={ this.state.coupon.validity === 'date' } />

                                        <Form.Control type='date' value={ this.state.coupon.validUntil } name='validUntil' onChange={ e => this.changeCoupon( e ) } disabled={ this.state.coupon.validity !== 'date' } />

                                    </InputGroup>

                                </Col>

                            </Form.Group>

                            <Form.Group as={ Row } className='mb-3' controlId='duration'>

                                <Form.Label column sm='4'>{ _( 'Gültigkeitsdauer' ) }</Form.Label>

                                <Col sm='8'>

                                    <InputGroup>

                                        <InputGroup.Radio name='validity' value='duration' onChange={ e => this.changeCoupon( e ) } checked={ this.state.coupon.validity === 'duration' } />

                                        <Form.Control type='number' min='1' value={ this.state.coupon.duration } name='duration' onChange={ e => this.changeCoupon( e ) } disabled={ this.state.coupon.validity !== 'duration' } />

                                        <InputGroup.Text>{ _( 'Tage' ) }</InputGroup.Text>

                                    </InputGroup>

                                </Col>

                            </Form.Group>

                            <Form.Group as={ Row } className='mb-3' controlId='accessibleId'>

                                <Form.Label column sm='4'>{ _( 'Accessible ID' ) }</Form.Label>

                                <Col sm='8'>

                                    <InputGroup>

                                        <InputGroup.Radio name='validity' value='accessibleId' onChange={ e => this.changeCoupon( e ) } checked={ this.state.coupon.validity === 'accessibleId' } />

                                        <Form.Control type='number' min='1' value={ this.state.coupon.accessibleId } name='accessibleId' onChange={ e => this.changeCoupon( e ) } disabled={ this.state.coupon.validity !== 'accessibleId' } />

                                        <InputGroup.Text>{ _( 'Livestream ID' ) }</InputGroup.Text>

                                    </InputGroup>

                                </Col>

                            </Form.Group>

                        </fieldset>

                        { this.state.coupon.code &&

                            <div className='border-top my-5 pt-5'>

                                <p className='lead text-center'>{ _( 'Gutscheincode' ) }</p>

                                <CtCInput value={ this.state.coupon.code } inputGroupProps={ { size: 'lg' } } />

                            </div>

                        }

                    </Card.Body>

                    <Card.Footer>

                        {

                            this.state.coupon.code ?

                                <Button onClick={ () => this.reset() } variant='secondary'>{ _( 'Weiteren Gutscheincode erstellen' ) }</Button>

                            :

                                <Button onClick={ () => this.createCoupon() } disabled={ this.state.isCreatingCoupon }>{ _( 'Gutscheincode erstellen' ) }</Button>

                        }

                    </Card.Footer>

                </Card>

            </>

        )

    }

}

export default CreateCouponCode