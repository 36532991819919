import React from 'react'
import { translate as _ } from '@hockeydata/skynet'
import { Button, Modal } from 'react-bootstrap'

class ConfirmationModal extends React.Component {

    render() {

        return (

            <Modal show={ this.props.show } onHide={ () => this.props.onHide() } centered>

                <Modal.Header closeButton>

                    <Modal.Title>{ this.props.title }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    <h3>{ this.props.heading }</h3>

                    <p className='lead'>{ this.props.text }</p>

                </Modal.Body>

                <Modal.Footer>

                    <Button onClick={ () => this.props.onHide() } variant='secondary'>{ _( 'Abbrechen' ) }</Button>
                    <Button onClick={ () => this.props.onConfirm() }>{ _( 'OK' ) }</Button>

                </Modal.Footer>

            </Modal>

        )

    }

}

export default ConfirmationModal