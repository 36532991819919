import { translate as _ } from '@hockeydata/skynet'
import { Badge } from 'react-bootstrap'
import StatusBadge from '../components/elements/StatusBadge'
import { BROADCAST_STATUS_ENDED, BROADCAST_STATUS_LIVE, BROADCAST_STATUS_SCHEDULED, CAMERA_STATUS_LIVE, CAMERA_STATUS_MAINTENANCE, CAMERA_STATUS_OFFLINE, CAMERA_STATUS_RESET, CAMERA_STATUS_SLEEP, CAMERA_SUPPLIER_PIXELLOT, EVENT_STATUS_FAILED, INCOMING_STREAM_STATUS_OFFLINE, INCOMING_STREAM_STATUS_ONLINE, INCOMING_STREAM_STATUS_UNKNOWN, PAYMENT_STATUS_CANCELLED, PAYMENT_STATUS_DONE, PAYMENT_STATUS_PENDING, PAYMENT_STATUS_REFUND } from './constants'
import { createDate, createDateWithOffset, fillDate, stripDate } from './date'

const broadcastStatuses = {

    [ BROADCAST_STATUS_ENDED     ]: 'light',
    [ BROADCAST_STATUS_LIVE      ]: 'primary',
    [ BROADCAST_STATUS_SCHEDULED ]: 'info',

}

const cameraStatuses = {

    [ CAMERA_STATUS_LIVE        ]: [ 'success', 'circle'             ],
    [ CAMERA_STATUS_MAINTENANCE ]: [ 'danger',  'wrench'             ],
    [ CAMERA_STATUS_OFFLINE     ]: [ 'primary', 'video-slash'        ],
    [ CAMERA_STATUS_RESET       ]: [ 'warning', 'circle-half-stroke' ],
    [ CAMERA_STATUS_SLEEP       ]: [ 'info',    'moon'               ],

}

const incomingStreamStatuses = {

    [ INCOMING_STREAM_STATUS_OFFLINE ]: [ 'primary', 'times'    ],
    [ INCOMING_STREAM_STATUS_ONLINE  ]: [ 'success', 'check'    ],
    [ INCOMING_STREAM_STATUS_UNKNOWN ]: [ 'warning', 'question' ],

}

const paymentStatuses = {

    [ PAYMENT_STATUS_CANCELLED ]: [ 'Storniert',      'warning' ],
    [ PAYMENT_STATUS_DONE      ]: [ 'Bezahlt',        'success' ],
    [ PAYMENT_STATUS_PENDING   ]: [ 'Offen',          'danger'  ],
    [ PAYMENT_STATUS_REFUND    ]: [ 'Rückerstattung', 'info'    ],

}

const soonLimit = 1000 * 60 * 60 * 6

export const findTextInObject = ( obj, searchString, keys ) => {

    if ( ! keys ) {

        keys = Object.keys( obj )

    }

    searchString = searchString.toLowerCase()

    for ( let i = 0; i < keys.length; i++ ) {

        const key   = keys[ i   ]
        const value = obj[  key ]

        if ( typeof value === 'string' && value.toLowerCase().indexOf( searchString ) !== -1 ) {

            return true

        }

    }

    return false

}

export const formatDuration = e => {

    return Math.floor( e / 60 ) + ':' + Math.floor( e % 60 )

}

export const getBroadcastStatusBackground = broadcastStatus => ( broadcastStatus in broadcastStatuses ) ? broadcastStatuses[ broadcastStatus ] : 'warning'

export const getCameraStatusLabel = cameraStatus => <StatusBadge title={ cameraStatus } bg={ cameraStatus in cameraStatuses ? cameraStatuses[ cameraStatus ][ 0 ] : 'warning' } icon={ cameraStatus in cameraStatuses ? cameraStatuses[ cameraStatus ][ 1 ] : 'triangle-exclamation' } label={ _( 'Kamera' ) } />

export const getDateRangePickerLocale = e => {

    return { ...{

        applyLabel:        _( 'Übernehmen' ),
        cancelLabel:       _( 'Abbrechen' ),
        customRangeLabel:  _( 'Custom' ),
        daysOfWeek:        [ _( 'So' ), _( 'Mo' ), _( 'Di' ), _( 'Mi' ), _( 'Do' ), _( 'Fr' ), _( 'Sa' ) ],
        firstDay:          1,
        format:            'DD.MM.YY',
        fromLabel:         _( 'Von' ),
        monthNames:        [ _( 'Januar' ), _( 'Februar' ), _( 'März' ), _( 'April' ), _( 'Mai' ), _( 'Juni' ), _( 'Juli' ), _( 'August' ), _( 'September' ), _( 'Oktober' ), _( 'November' ), _( 'Dezember' ) ],
        separator:         ' - ',
        toLabel:           _( 'Bis' ),
        weekLabel:         'W',

    }, ...( e || {} ) }

}

export const getDefaultDateRange = ( past ) => { return { from: stripDate( past ? createDateWithOffset( 1000 * 60 * 60 * 24 * 14 * -1 ) : createDate() ), to: fillDate( past ? createDate() : createDateWithOffset( 1000 * 60 * 60 * 24 * 14 ) ) } }

export const getEventStatusLabel = cameraMatchId => {

    let badge

    if ( cameraMatchId === null ) {

        badge = [ 'Unknown', 'warning', 'question' ]

    } else if ( cameraMatchId === EVENT_STATUS_FAILED ) {

        badge = [ 'Anlegen fehlgeschlagen', 'danger', 'triangle-exclamation' ]

    } else {

        badge = [ 'Event angelegt', 'success', 'check' ]

    }

    return <StatusBadge title={ _( badge[ 0 ] ) } bg={ badge[ 1 ] } icon={ badge[ 2 ] } label={ _( 'Event' ) } className='me-1' />

}

export const getIncomingStreamStatusLabel = incomingStreamStatus => <StatusBadge title={ incomingStreamStatus } bg={ incomingStreamStatuses[ incomingStreamStatus ][ 0 ] } icon={ incomingStreamStatuses[ incomingStreamStatus ][ 1 ] } label={ _( 'Incoming' ) } className='me-1' />

export const getLivestreamTitle = e => e.Title || '[' + e.Id + ']'

export const getPaymentStatusLabel = paymentStatus => {

    if ( ! paymentStatus ) {

        return null

    }

    paymentStatus = paymentStatus in paymentStatuses ? paymentStatuses[ paymentStatus ] : [ paymentStatus, 'dark' ]

    return <Badge bg={ paymentStatus[ 1 ] }>{ _( paymentStatus[ 0 ] ) }</Badge>

}

export const hasPrivilege = ( user, privilegeId ) => user && user.Modules && user.Modules.findIndex( modules => modules.Privileges ? modules.Privileges.findIndex( e => e.Id === privilegeId ) !== -1 : false ) !== -1

export const isPixellot = e => e && e.LocationCameras && e.LocationCameras.length > 0 && e.LocationCameras[ 0 ].CameraSupplier === CAMERA_SUPPLIER_PIXELLOT

export const isSoon = e => ( createDate( e.StartTime ) - createDate() ) < soonLimit

export const lz = ( e, length = 2 ) => {

    e = e + ''

    while ( e.length < length ) {

        e = '0' + e

    }

    return e

}

export const reloadPage = () => setTimeout( () => window.location.reload(), 200 )

export const sortByKey = ( e, key ) => e.sort( ( a, b ) => a[ key ] < b[ key ] ? -1 : a[ key ] > b[ key ] ? 1 : 0 )

export const sortByName = e => sortByKey( e, 'Name' )