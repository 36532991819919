import React from 'react'
import { Card, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class Workspace extends React.Component {

    render() {

        return (

            <Card className='mb-2'>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col className='fs-6'>

                            <Link to={ '/workspace/' + this.props.item.Id }>{ this.props.item.Name }</Link>

                        </Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

}

export default Workspace