import { connect } from 'react-redux'
import { storeActions } from '@hockeydata/skynet'
import Payments from '../../components/pages/Payments'
import { createDate } from '../../util/date'
import { setDateRange, setUiValue } from '../../store/actions'

const mapStateToProps = state => {

    return {

        dateRangeFrom: createDate( state.ui.dateRangeFromPayments ),
        dateRangeTo:   createDate( state.ui.dateRangeToPayments   ),
        isLoading:     state.app.isLoading,
        statusFilter:  state.ui.statusFilterPayments,
        textFilter:    state.ui.textFilterPayments,
        token:         state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onDateRangeChange:    ( from, to ) => dispatch( setDateRange( from, to, 'dateRangeFromPayments', 'dateRangeToPayments' ) ),
        onStatusFilterChange: statusFilter => dispatch( setUiValue( 'statusFilterPayments', statusFilter                       ) ),
        onTextFilterChange:   textFilter   => dispatch( setUiValue( 'textFilterPayments', textFilter                           ) ),
        onToggleIsLoading:    isLoading    => dispatch( storeActions.app.toggleIsLoading( isLoading                            ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Payments )