import { connect } from 'react-redux'
import User from '../../components/pages/User'
import { storeActions } from '@hockeydata/skynet'

const mapStateToProps = state => {

    return {

        token: state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onToggleIsLoading: isLoading => dispatch( storeActions.app.toggleIsLoading( isLoading ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( User )