import { Icon, translate as _ } from '@hockeydata/skynet'
import React from 'react'
import { Badge, Card, Col, Row } from 'react-bootstrap'
import { formatDate, formatTime } from '../../util/date'

class Suspension extends React.Component {

    render() {

        return (

            <Card className='mb-2 suspension-item'>

                <Card.Body>

                    <Row className='align-items-center'>

                        <Col xs='4' sm='3' md='2' xl='1' className='text-center'>

                            <Badge>

                                <div>{ formatDate( this.props.item.SuspensionDate, { weekday: 'short', year: 'none' } ) }</div>

                                <div>{ formatTime( this.props.item.SuspensionDate ) }</div>

                            </Badge>

                        </Col>

                        <Col xs='8' sm='9' md='7' xl='3'>

                            <div className='fs-6 line-height-1'>{ this.props.item.Lastname } { this.props.item.Firstname }</div>

                            { this.props.item.PassNr && <div>{ _( 'Passnr.' ) }: { this.props.item.PassNr }</div> }

                        </Col>

                        <Col xs='12' md='3' xl='2' className='fs-6 py-2 text-center'>

                            { this.props.item.From && formatDate( this.props.item.From ) }

                            { this.props.item.To && ' - ' + formatDate( this.props.item.To ) }

                        </Col>

                        <Col xs='6' xl='4' className='py-2'>

                            <div className='text-primary'>{ this.props.item.LeagueName ? <><Icon icon='trophy' /> { this.props.item.LeagueName }</> : ' ' }</div>

                            <div className='text-success'>{ this.props.item.DivisionName ? <><Icon icon='certificate' /> { this.props.item.DivisionName }</> : ' ' }</div>

                            <div className='text-info'>{ this.props.item.HomeTeam && this.props.item.AwayTeam ? <><Icon icon='shield' /> { this.props.item.HomeTeam } - { this.props.item.AwayTeam }</> : ' ' }</div>

                        </Col>

                        <Col xs='6' xl='2' className='py-2'>

                            <div>{ this.props.item.NumberOfGamesSuspended } { this.props.item.NumberOfGamesSuspended === 1 ? _( 'Spiel gesperrt' ) : _( 'Spiele gesperrt' ) }</div>

                            <div>{ Number( this.props.item.NumberOfGamesServed ) } { this.props.item.NumberOfGamesServed === 1 ? _( 'Spiel abgesessen' ) : _( 'Spiele abgesessen' ) }</div>

                            <div>{ this.props.item.NumberOfGamesOnProbation } { this.props.item.NumberOfGamesOnProbation === 1 ? _( 'Spiel auf Bewährung' ) : _( 'Spiele auf Bewährung' ) }</div>

                        </Col>

                    </Row>

                </Card.Body>

            </Card>

        )

    }

}

export default Suspension