const fileExtRE = /(?:\.([^.]+))?$/

export const getFileExtension = filename => fileExtRE.exec( filename )[ 1 ]

export const getReadableFileSize = ( bytes = 0 ) => {

    const thresh = 1024

    if ( Math.abs( bytes ) < thresh ) {

        return bytes + ' B'

    }

    const units = [ 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB' ]
    const r     = 2
      let u     = -1

    do {

        bytes /= thresh

        ++u

    } while ( Math.round( Math.abs( bytes) * r ) / r >= thresh && u < units.length - 1 )

    return bytes.toFixed( 2 ).replace( '.', ',' ) + ' ' + units[ u ]

}

export const uploadFile = ( options = {} ) => new Promise( ( resolve, reject ) => {

    const xhr = new XMLHttpRequest()

    xhr.upload.addEventListener( 'progress', e => options.onProgress && options.onProgress( e.loaded / e.total * 100 ) )

    xhr.addEventListener( 'load',  () => xhr.status >= 200 && xhr.status <= 299 ? resolve( JSON.parse( xhr.responseText ) ) : reject() )
    xhr.addEventListener( 'error', () => reject() )
    xhr.addEventListener( 'abort', () => reject() )

    xhr.open( 'POST', options.url, true                 )
    xhr.setRequestHeader( 'Accept',  'application/json' )

    options.auth && xhr.setRequestHeader( 'Authorization', 'Bearer ' + options.auth )

    xhr.send( options.formData || null )

} )