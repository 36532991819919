import React from 'react'
import { ajax, getDataUrl, translate as _, Icon } from '@hockeydata/skynet'
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class CreateUserModal extends React.Component {

    constructor( props ) {

        super( props )

        this.state = this.getDefaultState()

    }

    componentDidMount() {

        this.load()

    }

    componentDidUpdate() {

        this.props.show !== this.state.show && this.setState( {

            ...this.getDefaultState(),

            show: this.props.show,

        } )

    }

    change( e ) {

        this.setState( { [ e.target.name ]: e.target.value } )

    }

    error() {

        this.setState( { error: true, isSaving: false } )

    }

    getDefaultState() {

        return {

            emailAdress: '',
            error:       false,
            isSaving:    false,
            password:    '',
            role:        '',
            roles:       this.state && this.state.roles,
            show:        false,
            success:     false,
            userId:      null,

        }

    }

    load() {

        ajax( getDataUrl( 'api/Role/Get' ), { token: this.props.token }, { method: 'POST' } ).then( e => e.StatusId > 0 && this.setState( { roles: e.Data } ) )

    }

    save() {

        const addRoles = () => {

            ajax( getDataUrl( 'api/Role/AddUsers' ), { roleId: this.state.role, userIds: this.state.userId, token: this.props.token }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? this.setState( { isSaving: false, success: true } ) : this.error() )

        }

        this.setState( { error: false, isSaving: true }, () =>

            ajax( getDataUrl( 'api/Account/Register' ), { email: this.state.emailAdress, psw: this.state.password }, { method: 'POST' } )
                .then( e => e.StatusId > 0 ? this.setState( { userId: e.Data[ 0 ].Id }, addRoles ) : this.error() )

        )

    }

    render() {

        return (

            <Modal show={ this.state.show } onHide={ () => this.props.onHide() } centered backdrop='static'>

                <Modal.Header closeButton>

                    <Modal.Title>{ _( 'Neuer Benutzer' ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    <fieldset disabled={ this.state.isSaving || this.state.success }>

                        <Form.Group as={ Row } className='mb-3' controlId='emailAddress'>

                            <Form.Label column sm='4'>{ _( 'E-Mail-Adresse' ) }</Form.Label>

                            <Col sm='8'>

                                <Form.Control value={ this.state.emailAdress } name='emailAdress' onChange={ e => this.change( e ) } />

                            </Col>

                        </Form.Group>

                        <Form.Group as={ Row } className='mb-3' controlId='password'>

                            <Form.Label column sm='4'>{ _( 'Passwort' ) }</Form.Label>

                            <Col sm='8'>

                                <Form.Control value={ this.state.password } name='password' onChange={ e => this.change( e ) } />

                            </Col>

                        </Form.Group>

                        <Form.Group as={ Row } controlId='role'>

                            <Form.Label column sm='4'>{ _( 'Rolle' ) }</Form.Label>

                            <Col sm='8'>

                                <Form.Select name='role' value={ this.state.role } onChange={ e => this.change( e ) }>

                                    <option />

                                    { this.state.roles && this.state.roles.map( ( e, i ) => <option value={ e.Id } key={ i }>{ e.Name }</option> ) }

                                </Form.Select>

                                <Form.Text className='text-muted'>{ _( 'Weitere Rollen können später hinzugefügt werden.' ) }</Form.Text>

                            </Col>

                        </Form.Group>

                    </fieldset>

                    { this.state.error && <Alert variant='danger' className='mt-3 mb-0'><Icon icon='exclamation-triangle' /> { _( 'Benutzer konnte nicht erstellt werden.' ) }</Alert> }

                    { this.state.success && <Alert variant='success' className='mt-3 mb-0'><Icon icon='check' /> { _( 'Benutzer wurde erstellt.' ) }</Alert> }

                </Modal.Body>

                <Modal.Footer>

                    <Button onClick={ () => this.props.onHide() } disabled={ this.state.isSaving } variant='secondary'>{ this.state.success ? _( 'Schließen' ) : _( 'Abbrechen' ) }</Button>

                    { ! this.state.success && <Button onClick={ () => this.save() } disabled={ this.state.isSaving || ! this.state.emailAdress || ! this.state.password || ! this.state.role }>{ _( 'Benutzer erstellen' ) }</Button> }

                    { this.state.success && <Link to={ '/benutzer/' + this.state.userId } className='btn btn-primary'>{ _( 'Benutzer bearbeiten' ) }</Link> }

                </Modal.Footer>

            </Modal>

        )

    }

}

export default CreateUserModal