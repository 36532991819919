import { connect } from 'react-redux'
import { storeActions } from '@hockeydata/skynet'
import Users from '../../components/pages/Users'
import { setUiValue } from '../../store/actions'

const mapStateToProps = state => {

    return {

        isLoading:  state.app.isLoading,
        textFilter: state.ui.textFilterUsers || '',
        token:      state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onTextFilterChange: textFilter => dispatch( setUiValue( 'textFilterUsers', textFilter   ) ),
        onToggleIsLoading:  isLoading  => dispatch( storeActions.app.toggleIsLoading( isLoading ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Users )