export const BITMOVIN_PLAYER_KEY                      = '1a0fb32e-e4da-43ce-a5e1-1a059095e4d2'
export const BROADCAST_STATUS_ENDED                   = 'Ended'
export const BROADCAST_STATUS_LIVE                    = 'Live'
export const BROADCAST_STATUS_SCHEDULED               = 'Scheduled'
export const CAMERA_STATUS_LIVE                       = 'Live'
export const CAMERA_STATUS_MAINTENANCE                = 'Maintenance'
export const CAMERA_STATUS_OFFLINE                    = 'Offline'
export const CAMERA_STATUS_RESET                      = 'Reset'
export const CAMERA_STATUS_SLEEP                      = 'Sleep'
export const CAMERA_SUPPLIER_PIXELLOT                 = 'Pixellot'
export const ENTITY_TYPE_COMPETITION                  = 'Competition'
export const ENTITY_TYPE_TEAM                         = 'Team'
export const EVENT_STATUS_FAILED                      = 'failed'
export const INCOMING_STREAM_STATUS_OFFLINE           = 'Offline'
export const INCOMING_STREAM_STATUS_ONLINE            = 'Online'
export const INCOMING_STREAM_STATUS_UNKNOWN           = 'Unknown'
export const LIVESTREAMS_STATUS_FILTER_ALL_GAMES      = 0
export const LIVESTREAMS_STATUS_FILTER_BROADCAST      = 1
export const LIVESTREAMS_STATUS_FILTER_DONT_BROADCAST = 2
export const PAYMENTS_STATUS_FILTER_ALL               = 'PAYMENTS_STATUS_FILTER_ALL'
export const PAYMENTS_STATUS_FILTER_NONE              = 'PAYMENTS_STATUS_FILTER_NONE'
export const PAYMENT_STATUS_CANCELLED                 = 'CANCELLED'
export const PAYMENT_STATUS_DONE                      = 'DONE'
export const PAYMENT_STATUS_PENDING                   = 'PENDING'
export const PAYMENT_STATUS_REFUND                    = 'REFUND'
export const PAYMENT_SYSTEM_COUPON                    = 'COUPON'

// PRIVILEGES
export const PRIV_CANCEL_LIVESTREAM                   = 'A8F85D43-4063-41CD-A52D-E6C14C443CA0'
export const PRIV_CREATE_COUPON_CODE                  = '9D63C7C7-EFB1-4BBD-A19B-6119D369FC51'
export const PRIV_IGNORE_ACTIVITY                     = '6A6ACD79-5E79-49AC-9C36-C875CC69E2DE'
export const PRIV_LOAD_ACCOUNTS                       = '7CD7704D-49B5-42C9-9365-38C70DC2918F'
export const PRIV_LOAD_LEAGUES                        = '890C93F1-2767-443B-A35C-D162D805EE2A'
export const PRIV_LOAD_LIVESTREAMS                    = '6C1DA338-348F-4F8E-947A-6D45F3020FF4'
export const PRIV_LOAD_PAYMENTS                       = '32C11BF6-2839-43FD-B7BE-71F25E34EA11'
export const PRIV_LOAD_SUSPENSIONS                    = '5C736518-2FC6-41C5-A073-7C8E16EA6861'
export const PRIV_LOAD_WORKSPACES                     = '4D17D8E2-64BF-41BB-8891-70C0A4457567'
export const PRIV_REFRESH_GAMES                       = 'BE14DCE8-A6D5-4A43-B488-8B25334CB03E'
export const PRIV_RESET_LIVESTREAM                    = 'EEA0D7FA-4111-45B5-96F2-C2B0ED960CE4'
export const PRIV_SAVE_SUPPORT_FIELDS                 = '0BDD8985-78F8-45CE-B01A-D30C28953C51'
export const PRIV_SET_GAME_IGNORE_OVERLAYS            = 'D4492244-0EF5-4D66-A750-3CE85AB29442'
export const PRIV_SET_GAME_PRICE                      = '17CC52D0-88DB-4F4B-8818-A0F4F3CE073E'
export const PRIV_SET_GAME_STATUS                     = '1B6C1908-44FB-4659-AAA2-8E8A4007FEBD'
export const PRIV_STOP_LIVESTREAM                     = 'C49901F6-EBB8-42D6-B17C-C0EE8EF35EFD'
export const PRIV_UPDATE_LIVESTREAM_TITLE             = '5242D474-B5A6-41C3-A931-7F7522FBE2D0'
export const PRIV_UPLOAD_STREAM_VIDEO_FILE            = 'UPLOAD_STREAM_VIDEO_FILE'
export const PRIV_VIEW_GAME_NOTES                     = 'VIEW_GAME_NOTES'
export const PRIV_VIEW_STREAM_INFO                    = 'VIEW_STREAM_INFO'
export const PRIV_VIEW_STREAM_OUTPUT_INFO             = 'VIEW_STREAM_OUTPUT_INFO'
export const PRIV_VIEW_STREAM_PLAYER                  = 'VIEW_STREAM_PLAYER'

// export const PRIV_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX                       = 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'
// export const PRIV_XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX                       = 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX'