import React from 'react'
import { Icon, Login, MenuItem, Skynet } from '@hockeydata/skynet'
import { Route } from 'react-router-dom'
import packageJson from '../../../package.json'
import { PRIV_CREATE_COUPON_CODE, PRIV_LOAD_ACCOUNTS, PRIV_LOAD_LEAGUES, PRIV_LOAD_LIVESTREAMS, PRIV_LOAD_PAYMENTS, PRIV_LOAD_SUSPENSIONS, PRIV_LOAD_WORKSPACES } from '../util/constants'
import Livestreams from '../containers/pages/Livestreams'
import Payments from '../containers/pages/Payments'
import Suspensions from '../containers/pages/Suspensions'
import Leagues from '../containers/pages/Leagues'
import { hasPrivilege, reloadPage } from '../util'
import ErrorBoundary from './ErrorBoundary'
import { Button, Toast, ToastContainer } from 'react-bootstrap'
import { createDate } from '../util/date'
import CreateCouponCode from '../containers/pages/CreateCouponCode'
import Users from '../containers/pages/Users'
import User from '../containers/pages/User'
import Workspaces from '../containers/pages/Workspaces'
import Workspace from '../containers/pages/Workspace'

class App extends React.Component {

    constructor( props ) {

        super( props )

        this.state = {

            appUpdateAvailable: false,
            updatingApp:        false,

        }

    }

    componentDidMount() {

        setTimeout( () => {

            if ( window.appUpdateAvailable ) {

                this.setState( { appUpdateAvailable: true } )

                setTimeout( () => this.setState( { appUpdateAvailable: false } ), 1000 * 60 )

            }

        }, 1000 * 5 )

    }

    hideAppUpdateNotification() {

        this.setState( { appUpdateAvailable: false } )

    }

    updateApp() {

        this.setState( { updatingApp: true }, () => {

            caches
                .keys()
                .then( keys => keys.forEach( c => caches.delete( c ) ) )
                .then( () => setTimeout( reloadPage, 1000 * 3 ) )

        } )

    }

    render() {

        const logoImageUrl          = '/assets/logo-wide.png'
        let mainNavigationMenuItems = []
        let pageRoutes              = []

        if ( hasPrivilege( this.props.user, PRIV_LOAD_LIVESTREAMS ) ) {

            mainNavigationMenuItems.push( { url: '/livestreams', children: <><Icon fw icon='video' /><span className='nav-link-text'>Livestreams</span></> } )
            pageRoutes.push( { path: '/livestreams', children: <Livestreams /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_WORKSPACES ) ) {

            mainNavigationMenuItems.push( { url: '/workspaces', children: <><Icon fw icon='layer-group' /><span className='nav-link-text'>Workspaces</span></> } )
            pageRoutes.push( { path: '/workspace/:id', children: <Workspace /> } )
            pageRoutes.push( { path: '/workspaces', children: <Workspaces /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_PAYMENTS ) ) {

            mainNavigationMenuItems.push( { url: '/payments', children: <><Icon fw icon='money-bill' /><span className='nav-link-text'>Payments</span></> } )
            pageRoutes.push( { path: '/payments', children: <Payments /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_CREATE_COUPON_CODE ) ) {

            mainNavigationMenuItems.push( { url: '/gutscheincode-erstellen', children: <><Icon fw icon='ticket' /><span className='nav-link-text'>Gutscheincode erstellen</span></> } )
            pageRoutes.push( { path: '/gutscheincode-erstellen', children: <CreateCouponCode /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_SUSPENSIONS ) ) {

            mainNavigationMenuItems.push( { url: '/aktive-sperren', children: <><Icon fw icon='ban' /><span className='nav-link-text'>Aktive Sperren</span></> } )
            pageRoutes.push( { path: '/aktive-sperren', children: <Suspensions /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_LEAGUES ) ) {

            mainNavigationMenuItems.push( { url: '/ligen', children: <><Icon fw icon='trophy' /><span className='nav-link-text'>Ligen</span></> } )
            pageRoutes.push( { path: '/ligen', children: <Leagues /> } )

        }

        if ( hasPrivilege( this.props.user, PRIV_LOAD_ACCOUNTS ) ) {

            mainNavigationMenuItems.push( { url: '/benutzer', children: <><Icon fw icon='users' /><span className='nav-link-text'>Benutzer</span></> } )
            pageRoutes.push( { path: '/benutzer/:id', children: <User /> } )
            pageRoutes.push( { path: '/benutzer', children: <Users /> } )

        }

        if ( mainNavigationMenuItems.length ) {

            mainNavigationMenuItems[ 0 ].url = '/'

        }

        if ( pageRoutes.length ) {

            if ( pageRoutes.length >= 2 && pageRoutes[ 0 ].path.endsWith( ':id' ) ) {

                const firstPageRouteWithoutIdIndex = pageRoutes.findIndex( e => ! e.path.endsWith( ':id' ) )

                if ( firstPageRouteWithoutIdIndex !== -1 ) {

                    const firstPageRouteWithoutId = pageRoutes.splice( firstPageRouteWithoutIdIndex, 1 )[ 0 ]
                    firstPageRouteWithoutId.path  = '/'

                    pageRoutes.push( firstPageRouteWithoutId )

                }

            } else {

                const firstPageRoute = pageRoutes.shift()
                firstPageRoute.path  = '/'

                pageRoutes.push( firstPageRoute )

            }

        }

        mainNavigationMenuItems = mainNavigationMenuItems.map( ( e, i ) => <MenuItem url={ e.url } key={ i } className={ e.className || '' }>{ e.children }</MenuItem> )
        pageRoutes              = pageRoutes.map( ( e, i ) => <Route path={ e.path } key={ i } children={ e.children } /> )

        const baseDataUrls = {

            'dev':                    'https://backend-beta-support.morawa.digital/',
            'support.morawa.digital': 'https://backend-support.morawa.digital/',

        }

        let baseDataUrl

        if ( process.env.REACT_APP_BASE_DATA_URL ) {

            baseDataUrl = process.env.REACT_APP_BASE_DATA_URL

            if ( process.env.REACT_APP_BASE_DATA_PORT ) {

                baseDataUrl += ':' + process.env.REACT_APP_BASE_DATA_PORT + '/'

            }

        } else if ( document.domain in baseDataUrls ) {

            baseDataUrl = baseDataUrls[ document.domain ]

        } else {

            baseDataUrl = baseDataUrls.dev

        }

        const conf ={

            footerLeft:    createDate().getFullYear() + ' © Data in Motion',
            footerRight:   'Data in Motion Support v' + packageJson.version,
            rootComponent: <Login logoImageUrl={ logoImageUrl } />,

            baseDataUrl,
            logoImageUrl,
            mainNavigationMenuItems,
            pageRoutes,

        }

        return (

            <ErrorBoundary>

                <Skynet { ...conf } />

                <ToastContainer position='bottom-center' containerPosition='fixed'>

                    <Toast show={ this.state.appUpdateAvailable } onClose={ () => this.hideAppUpdateNotification() }>

                        <Toast.Header>

                            <div className='flex-grow-1'>Update verfügbar</div>

                        </Toast.Header>

                        <Toast.Body>

                            <p>Eine neue App-Version ist verfügbar!</p>

                            <Button onClick={ () => this.updateApp() } disabled={ this.state.updatingApp }>{ this.state.updatingApp ? 'Installiere...' : 'Jetzt installieren' }</Button>

                        </Toast.Body>

                    </Toast>

                </ToastContainer>

            </ErrorBoundary>

        )

    }

}

export default App