import { connect } from 'react-redux'
import Workspace from '../../components/pages/Workspace'
import { storeActions } from '@hockeydata/skynet'

const mapStateToProps = state => {

    return {

        token: state.auth.token,

    }

}

const mapDispatchToProps = dispatch => {

    return {

        onToggleIsLoading: isLoading => dispatch( storeActions.app.toggleIsLoading( isLoading ) ),

    }

}

export default connect( mapStateToProps, mapDispatchToProps )( Workspace )