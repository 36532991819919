import React from 'react'
import { translate as _ } from '@hockeydata/skynet'
import { Button, Col, Form, Modal, Row } from 'react-bootstrap'
import { getLivestreamTitle, isPixellot } from '../../util'
import CtCInput from './CtCInput'

class LivestreamInfoModal extends React.Component {

    renderInput( options ) {

        return (

            <Form.Group as={ Row } className='mb-3' controlId={ options.id }>

                <Form.Label column sm='3'>{ options.label }</Form.Label>

                <Col sm='9'>

                    <CtCInput value={ options.value } />

                </Col>

            </Form.Group>

        )

    }

    renderPixellotStreamInfo() {

        return (

            <>

                <p dangerouslySetInnerHTML={ { __html: _( 'Erstelle ein Event auf <a href="https://club.pixellot.tv/" target="_blank">club.pixellot.tv</a> und gib bei Streamforwarding folgende Informationen ein:' ) } } />

                { this.renderInput( { id: 'streamName', label: _( 'Stream Name' ), value: 'Morawa' } ) }

                { this.renderInput( { id: 'streamTarget', label: _( 'Stream Target' ), value: 'Other' } ) }

                { this.renderInput( { id: 'streamType', label: _( 'Stream Type' ), value: 'rtmp' } ) }

                { this.renderInput( { id: 'application', label: _( 'Application' ), value: 'RawInput' } ) }

                { this.renderInput( { id: 'host', label: _( 'Host' ), value: '162.55.138.79' } ) }

                { this.renderInput( { id: 'port', label: _( 'Port' ), value: '1935' } ) }

                { this.renderInput( { id: 'streamKey', label: _( 'Stream Key' ), value: this.props.livestream.LocationCameras && this.props.livestream.LocationCameras.length ? this.props.livestream.LocationCameras[ 0 ].StreamKey : '' } ) }

            </>

        )

    }

    renderStreamInfo() {

        return (

            <>

                <p>{ _( 'Bitte gib beim Streaminganbieter folgende Infos mit:' ) }</p>

                { this.renderInput( { id: 'streamUrl', label: _( 'Stream URL' ), value: 'rtmp://162.55.138.79:1935/RawInput' } ) }

                { this.renderInput( { id: 'streamkey', label: _( 'Stream Key' ), value: this.props.livestream.LocationCameras && this.props.livestream.LocationCameras.length ? this.props.livestream.LocationCameras[ 0 ].StreamKey : '' } ) }

            </>

        )

    }

    render() {

        return (

            <Modal show={ this.props.show } onHide={ () => this.props.onHide() } centered>

                <Modal.Header closeButton>

                    <Modal.Title>{ getLivestreamTitle( this.props.livestream ) }</Modal.Title>

                </Modal.Header>

                <Modal.Body>

                    <h4>{ _( 'Streaminfo' ) }</h4>

                    { isPixellot( this.props.livestream ) ? this.renderPixellotStreamInfo() : this.renderStreamInfo() }

                </Modal.Body>

                <Modal.Footer>

                    <Button onClick={ () => this.props.onHide() }>{ _( 'Schließen' ) }</Button>

                </Modal.Footer>

            </Modal>

        )

    }

}

export default LivestreamInfoModal