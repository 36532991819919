import React from 'react'
import { Badge, OverlayTrigger, Popover } from 'react-bootstrap'
import { Icon } from '@hockeydata/skynet'

class StatusBadge extends React.Component {

    render() {

        return (

            <OverlayTrigger overlay={<Popover><Popover.Body>{ this.props.title }</Popover.Body></Popover>}>

                <div className={ 'status-badge' + ( this.props.className ? ' ' + this.props.className : '' ) }>

                    <div className='status-badge-label'>{ this.props.label }</div>

                    <Badge bg={ this.props.bg }>

                        <Icon icon={ this.props.icon } />

                    </Badge>

                </div>

            </OverlayTrigger>

        )

    }

}

export default StatusBadge